import React, { Component } from 'react';
import PropTypes from 'react-proptypes';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Tabs, Tab, Button, TextField, ListSubheader, Select, MenuItem,
  FormControl, InputLabel, Input,
} from '@material-ui/core';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import toolbarEditorStyle from '../static/toolbarEditorStyle';
import '../static/editorDescription.css';
import CoverageEditorContainer from '../Containers/CoverageEditorContainer';
import IntegrationEditorContainer from '../Containers/IntegrationEditorContainer';
import FrameContainer from './FrameContainer';
import InsurerBranchPickerContainer from '../Containers/InsurerBranchPickerContainer';

class ModalityFormComponent extends Component {
  constructor(props) {
    super(props);
    const content = stateFromHTML(props.modality.description);
    this.state = {
      valueTab: 0,
      editorState: EditorState.createWithContent(content),
    };
  }

  getCategories = () => {
    const { categories } = this.props;
    return categories.map((category) => (
      <MenuItem
        value={category.slug}
        key={category.slug}
        id={category.id}
      >
        {category.name}
      </MenuItem>
    ));
  }

  handleChangeTabs = (value) => {
    this.setState({
      valueTab: value,
    });
  }

  handleDescriptionChange = (editorState) => {
    const { handleFieldChange } = this.props;
    this.setState({
      editorState,
    });
    handleFieldChange('description', draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }

  selectCategory = (categorySlug) => {
    const { categories, handleFieldChange } = this.props;
    const selectedCategory = categories.find((category) => category.slug === categorySlug);
    handleFieldChange('category', selectedCategory !== undefined ? selectedCategory : null);
  }

  render = () => {
    const {
      modality, save, cancelEdit, handleFieldChange, classes, enableSaveButton, categories, title,
    } = this.props;
    const {
      editorState, valueTab,
    } = this.state;

    return (
      <FrameContainer
        titleOverride={title}
        backButton={cancelEdit}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '1em',
            flexGrow: 1,
          }}
        >
          <Tabs
            value={valueTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            onChange={(event, value) => this.handleChangeTabs(value)}
          >
            <Tab id="dataTab" className={classes.tabs} label="Datos Básicos" value={0} />
            <Tab id="coveragesTab" className={classes.tabs} label="Coberturas" value={1} />
            <Tab id="integrationsTab" className={classes.tabs} label="Integraciones" value={2} />
          </Tabs>

          {valueTab === 0 && (
            <div
              style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}
            >
              <InsurerBranchPickerContainer
                branch={modality.branch}
                insurer={modality.insurer}
                onChange={handleFieldChange}
              />
              <div>
                <TextField
                  id="editorName"
                  value={modality.name}
                  style={{ width: '400px', marginRight: 10 }}
                  onChange={(event) => handleFieldChange('name', event.target.value)}
                  label="Nombre"
                  helperText={modality.name === '' ? 'Campo obligatorio' : ''}
                />
                <FormControl>
                  <InputLabel shrink htmlFor="select-category-placeholder">
                    Categoria
                  </InputLabel>
                  <Select
                    disabled={categories.length === 0}
                    style={{ minWidth: 150 }}
                    onChange={(event) => { this.selectCategory(event.target.value); }}
                    value={modality.category ? modality.category.slug : ''}
                    autoWidth
                    input={<Input name="category" id="select-category-placeholder" />}
                  >
                    {this.getCategories()}
                  </Select>
                </FormControl>
                <FormControl required>
                  <InputLabel shrink htmlFor="select-type-placeholder">
                    Tipo
                  </InputLabel>
                  <Select
                    style={{ minWidth: 150, marginLeft: '20px' }}
                    onChange={(event) => handleFieldChange('type', event.target.value)}
                    value={modality.type}
                    autoWidth
                    input={<Input name="type" id="select-type-placeholder" />}
                  >
                    <MenuItem value="MAIN">Principal</MenuItem>
                    <MenuItem value="COMPLEMENTARY">Complementario</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <ListSubheader style={{ marginTop: '20px', backgroundColor: 'lightgrey' }}>
                Aseguradora del modalidad
              </ListSubheader>
              <Editor
                id="editorDescription"
                toolbar={toolbarEditorStyle}
                wrapperClassName="editorDescription"
                key={`${modality.slug}_description`}
                editorState={editorState}
                onEditorStateChange={this.handleDescriptionChange}
              />
            </div>
          )}

          {valueTab === 1 && (
            <div
              style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}
            >
              <div>
                <div id="divCoverages">
                  <CoverageEditorContainer
                    branch={modality.branch !== null ? modality.branch : null}
                    objectCoverages={modality.coverages}
                    onChange={(objectCoverages) => handleFieldChange('coverages', objectCoverages)}
                  />
                </div>
              </div>
            </div>
          )}

          {valueTab === 2 && (
            <div
              style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}
            >
              <div>
                <div id="divIntegrations">
                  <IntegrationEditorContainer
                    modalityId={modality.id}
                    objectIntegrations={modality.integrations}
                    onChange={(objectIntegrations) => handleFieldChange('integrations', objectIntegrations)}
                  />
                </div>
              </div>
            </div>
          )}

          <div id="divactions0" style={{ float: 'right' }}>
            <Button
              variant="contained"
              key="saveButton"
              id="saveButton"
              color="primary"
              disabled={!enableSaveButton}
              onClick={() => {
                save();
              }}
            >
              Guardar
            </Button>
          </div>
        </div>

      </FrameContainer>
    );
  }
}

ModalityFormComponent.propTypes = {
  title: PropTypes.string,
  save: PropTypes.func.isRequired,
  enableSaveButton: PropTypes.bool.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  modality: PropTypes.shape({
    branch: PropTypes.shape({}),
    insurer: PropTypes.shape({}),
    id: PropTypes.string,
    slug: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.shape({
      slug: PropTypes.string,
    }),
    type: PropTypes.string,
    integrations: PropTypes.arrayOf(PropTypes.shape({})),
    coverages: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  classes: PropTypes.shape().isRequired,
};

ModalityFormComponent.defaultProps = {
  title: null,
};

const styles = (theme) => ({
  tabs: {
    backgroundColor: theme.palette.primary.main,
  },
});

export default withStyles(styles)(ModalityFormComponent);
