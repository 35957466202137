import React from 'react';
import PropTypes from 'react-proptypes';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Login } from '../Redux/Actions';
import LoginComponent from '../Components/LoginComponent';

const UnconnectedLoginContainer = ({ logged, actions: { login } }) => {
  if (logged) {
    return (
      <Redirect to={{
        pathname: '/',
      }}
      />
    );
  }

  return (
    <LoginComponent onSubmit={login} />
  );
};

export const mapDispatchToProps = (dispatch) => ({
  actions: {
    login: (username, password) => {
      dispatch(Login.login(username, password));
    },
  },
});

export const mapStateToProps = (state) => ({
  logged: state.Login.logged,
});

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedLoginContainer);

UnconnectedLoginContainer.propTypes = {
  actions: PropTypes.shape({
    login: PropTypes.func.isRequired,
  }).isRequired,
  logged: PropTypes.bool.isRequired,
};

export default LoginContainer;
