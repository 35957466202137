import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'react-proptypes';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import NavigationBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import {
  Toolbar, Typography, Drawer, List, ListItem, ListItemText, CssBaseline, Snackbar,
} from '@material-ui/core';
import { Login } from '../Redux/Actions';

const sections = [
  {
    id: 'insurers',
    key: 'insurersEditor',
    name: 'Editar Aseguradoras',
    uri: '/insurers',
  },
  {
    id: 'coverages',
    key: 'coveragesEditor',
    name: 'Editar Coberturas',
    uri: '/coverages',
  },
  {
    id: 'modalities',
    key: 'modalitiesEditor',
    name: 'Editar Modalidades',
    uri: '/modalities',
  },
  {
    id: 'categories',
    key: 'categoriesEditor',
    name: 'Editar Categorías',
    uri: '/categories',
  },
];

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  paper: {
    width: 240,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(3),
  },
});


class UnconnectedFrameContainer extends PureComponent {
  signOut = () => {
    const { actions: { signOut } } = this.props;
    signOut();
  }

  render() {
    const {
      location, history, children, backButton, titleOverride, classes, shouldUpdate,
    } = this.props;
    const menuItems = sections.map((section) => (
      <ListItem
        button
        id={section.id}
        key={section.name}
        onClick={() => { history.push(section.uri); }}
      >
        <ListItemText primary={section.name} />
      </ListItem>
    ));
    let title = titleOverride;
    // FIXME esto se debería ir fuera, que cada seccion pase el titulo
    sections.forEach((section) => {
      if (section.uri === location.pathname) {
        title = section.name;
      }
    });
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          color="primary"
          className={classes.appBar}
        >
          <Toolbar>
            {backButton !== null
              && (
                <IconButton onClick={backButton} color="inherit">
                  <NavigationBack />
                </IconButton>
              )}
            <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }}>
              {title}
            </Typography>
            <Button variant="text" color="inherit" onClick={this.signOut}>Sign Out</Button>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" className={classes.drawer} classes={{ paper: classes.paper }}>
          <div className={classes.toolbar} />
          <List>
            {menuItems}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={shouldUpdate}
          message={<span>Hay una nueva version!</span>}
          action={
            <Button size="small" color="primary" onClick={() => window.location.reload()}>Actualizar</Button>
          }
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    signOut: () => {
      dispatch(Login.logout());
    },
  },
});

const mapStateToProps = (state) => ({
  shouldUpdate: state.Version.shouldUpdate,
});

const FrameContainer = withStyles(styles)(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedFrameContainer)));

UnconnectedFrameContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  children: PropTypes.shape(),
  backButton: PropTypes.func,
  titleOverride: PropTypes.string,
  actions: PropTypes.shape({
    signOut: PropTypes.func.isRequired,
  }).isRequired,
  classes: PropTypes.shape().isRequired,
  shouldUpdate: PropTypes.bool.isRequired,
};

UnconnectedFrameContainer.defaultProps = {
  backButton: null,
  titleOverride: '',
  children: null,
};

export default FrameContainer;
