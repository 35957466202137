import React from 'react';
import PropTypes from 'react-proptypes';
import {
  TableCell, Checkbox, TextField, TableRow,
} from '@material-ui/core';

export default function CoverageEditorRow({
  coverage,
  modalityCoverage,
  checkProductCoverage,
  handleCoverageChange,
}) {
  return (
    <TableRow
      key={coverage.id}
    >
      <TableCell style={{ width: '150px', fontSize: '15px' }}>{coverage.name}</TableCell>
      <TableCell style={{ width: '30px' }}>
        <Checkbox
          id={coverage.id}
          value={coverage.id}
          color="primary"
          checked={modalityCoverage !== null}
          onChange={(event, checked) => {
            checkProductCoverage(coverage.id, checked);
          }}
        />
      </TableCell>
      <TableCell style={{ width: '50px' }}>
        <TextField
          id={`${coverage.name}_capital`}
          value={modalityCoverage !== null ? modalityCoverage.capital : ''}
          disabled={modalityCoverage === null}
          onChange={(event) => {
            handleCoverageChange('capital', event.currentTarget.value, coverage.id);
          }}
        />
      </TableCell>
      <TableCell style={{ width: '50px' }}>
        <TextField
          id={`${coverage.name}_franchise`}
          value={modalityCoverage !== null ? modalityCoverage.franchise : ''}
          disabled={modalityCoverage === null}
          onChange={(event) => {
            handleCoverageChange('franchise', event.currentTarget.value, coverage.id);
          }}
        />
      </TableCell>
      <TableCell style={{ width: '50px' }}>
        <TextField
          id={`${coverage.name}_percentage`}
          value={modalityCoverage !== null ? modalityCoverage.percentage : ''}
          disabled={modalityCoverage === null}
          onChange={(event) => {
            handleCoverageChange('percentage', event.currentTarget.value, coverage.id);
          }}
        />
      </TableCell>
      <TableCell style={{ width: '550px' }}>
        <TextField
          id={`${coverage.name}_description`}
          value={modalityCoverage !== null ? modalityCoverage.description : ''}
          disabled={modalityCoverage === null}
          multiline
          rowsMax={6}
          style={{
            width: '100%',
          }}
          onChange={(event) => {
            handleCoverageChange('description', event.currentTarget.value, coverage.id);
          }}
        />
      </TableCell>
    </TableRow>
  );
}

CoverageEditorRow.propTypes = {
  coverage: PropTypes.shape().isRequired,
  modalityCoverage: PropTypes.shape(),
  checkProductCoverage: PropTypes.func.isRequired,
  handleCoverageChange: PropTypes.func.isRequired,
};

CoverageEditorRow.defaultProps = {
  modalityCoverage: null,
};
