import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'react-proptypes';
import {
  Branches, Common, Categories,
} from '../Redux/Actions';
import CategoriesListComponent from '../Components/CategoriesListComponent';

class CategoriesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryToEdit: null,
    };
  }

  componentDidMount = () => {
    const { branch, actions: { fetchCategories } } = this.props;
    if (branch !== null) {
      fetchCategories(branch.slug);
    }
  }

  createCategory = () => {
    this.setState({
      categoryToEdit: {
        id: null,
        name: '',
        slug: '',
      },
    });
  }

  editCategory = (categorySlug) => {
    const { categories } = this.props;

    let categoryToEdit = null;
    for (let i = 0; i < categories.length; i += 1) {
      if (categories[i].slug === categorySlug) {
        categoryToEdit = categories[i];
        break;
      }
    }

    this.setState({
      categoryToEdit,
    });
  }

  saveEdit = (data) => {
    const { branch, actions: { create, save } } = this.props;
    if (data.id === null) {
      create(
        data.name,
        branch.id,
        branch.slug,
      );
    } else {
      save(
        data.id,
        data.name,
        branch.slug,
        branch.id,
      );
    }

    this.setState({
      categoryToEdit: null,
    });
  }

  cancelEdit = () => {
    this.setState({
      categoryToEdit: null,
    });
  }

  loadCategories = (branch) => {
    const { actions: { fetchCategories, pickBranch } } = this.props;
    fetchCategories(branch.slug);
    pickBranch(branch);
  }

  render = () => {
    const {
      branches, categories, branch,
    } = this.props;
    const { categoryToEdit } = this.state;

    return (
      <CategoriesListComponent
        selectBranch={this.loadCategories}
        selectCategory={this.editCategory}
        branches={branches}
        categories={categories}
        addButtonClick={this.createCategory}
        selectedBranch={branch ? branch.slug : null}
        save={this.saveEdit}
        category={categoryToEdit}
        cancelEdit={this.cancelEdit}
      />
    );
  }
}

export const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchCategories: (branchSlug) => {
      dispatch(Categories.fetchCategories(branchSlug));
    },
    closeDialog: () => {
      dispatch(Common.closeDialog());
    },
    save: (id, name, slug, branchSlug) => {
      dispatch(Categories.saveCategory(id, name, slug, branchSlug));
    },
    create: (name, branchId, branchSlug) => {
      dispatch(Categories.createCategory(name, branchId, branchSlug));
    },
    pickBranch: (branch) => {
      dispatch(Branches.pickBranch(branch));
    },
  },
});

export const mapStateToProps = (state) => ({
  branches: state.Branches.branches,
  categories: state.Categories.categories,
  branch: state.Branches.branch,
});

CategoriesContainer.propTypes = {
  actions: PropTypes.shape({
    closeDialog: PropTypes.func.isRequired,
    fetchCategories: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    pickBranch: PropTypes.func.isRequired,
  }).isRequired,
  branches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string,
  })).isRequired,
  branch: PropTypes.shape({
    slug: PropTypes.string,
    id: PropTypes.string,
  }),
};

CategoriesContainer.defaultProps = {
  branch: null,
};

const ConnectedCategoriesContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoriesContainer));

export default ConnectedCategoriesContainer;
