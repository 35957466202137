import React from 'react';
import PropTypes from 'react-proptypes';
import {
  List, ListItem, IconButton, Paper, MenuItem,
  Button, Select, ListItemText, FormControl, InputLabel, Input,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import CoverageEditorComponent from './CoverageEditorComponent';
import FrameContainer from './FrameContainer';


const CoveragesListComponent = ({
  branches, selectedBranch, selectBranch, coverages,
  selectCoverage, addButtonClick, coverage, save, cancelEdit,
}) => {
  const showbranches = branches.map((branch) => (
    <MenuItem
      value={branch.slug}
      key={branch.slug}
    >
      {branch.name}
    </MenuItem>
  ));

  let listItems = null;
  if (selectedBranch !== null && selectedBranch !== '') {
    if (coverages && coverages.length > 0) {
      listItems = coverages.map((coverageItem) => {
        const selectCoverageConst = () => { selectCoverage(coverageItem.slug); };
        return (
          <ListItem
            key={coverageItem.slug}
          >
            <ListItemText
              primary={coverageItem.name}
              secondary={<span style={{ fontSize: '12px' }}>{`Slug: ${coverageItem.slug}`}</span>}
            />
            <IconButton
              className="editCoverageButton"
              onClick={selectCoverageConst}
            >
              <Edit />
            </IconButton>
          </ListItem>
        );
      });
    } else {
      listItems = (
        <ListItem
          disabled
        >
          <ListItemText
            primary="No existen coberturas para este ramo, crea alguna usando el botón de añadir"
          />
        </ListItem>
      );
    }
  } else {
    listItems = (
      <ListItem
        disabled
      >
        <ListItemText
          primary="Selecciona un Ramo"
        />
      </ListItem>
    );
  }

  return (
    <FrameContainer>
      <div>
        <div style={{
          display: 'flex', padding: '10px', margin: '0 auto', flexGrow: 1, flexDirection: 'column',
        }}
        >
          <div style={{
            flexDirection: 'row',
            width: '100%',
            display: 'flex',
          }}
          >
            <div
              style={{
                flexGrow: 1,
              }}
            >
              <FormControl
                id="branchSelector"
              >
                <InputLabel shrink htmlFor="select-branch-placeholder">
                  Ramo
                </InputLabel>
                <Select
                  autoWidth
                  input={<Input name="type" id="select-branch-placeholder" />}
                  style={{
                    minWidth: 150,
                  }}
                  key="branchSelector"
                  value={selectedBranch !== null ? selectedBranch : ''}
                  onChange={(event) => {
                    const selected = branches.find((row) => row.slug === event.target.value);
                    selectBranch(selected !== undefined ? selected : null);
                  }}
                >
                  {showbranches}
                </Select>
              </FormControl>
            </div>
            <Button
              variant="contained"
              id="addCoverageButton"
              style={{
                alignSelf: 'flex-end',
                margin: '5px',
              }}
              disabled={selectedBranch === null || selectBranch === ''}
              onClick={addButtonClick}
              color="primary"
            >
              Añadir
            </Button>
          </div>
          <Paper>
            <List
              id="coveragesList"
            >
              {listItems}
            </List>
          </Paper>
        </div>
        {coverage !== null
          ? (
            <CoverageEditorComponent
              save={save}
              coverage={coverage}
              cancelEdit={cancelEdit}
            />
          )
          : null}
      </div>
    </FrameContainer>
  );
};

CoveragesListComponent.propTypes = {
  branches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedBranch: PropTypes.string,
  coverages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectCoverage: PropTypes.func.isRequired,
  selectBranch: PropTypes.func,
  addButtonClick: PropTypes.func.isRequired,
  coverage: PropTypes.shape({}),
  save: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

CoveragesListComponent.defaultProps = {
  selectBranch: null,
  selectedBranch: null,
  coverage: null,
};
export default CoveragesListComponent;
