import React from 'react';
import PropTypes from 'react-proptypes';
import {
  List, ListItem, IconButton, Paper, MenuItem, Button,
  Select, ListItemText, FormControl, InputLabel, Input,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import CategoryEditorComponent from './CategoryEditorComponent';
import FrameContainer from './FrameContainer';


const CategoriesListComponent = ({
  branches, selectedBranch, selectBranch, categories,
  selectCategory, addButtonClick, category, save, cancelEdit,
}) => {
  const showbranches = branches.map((branch) => (
    <MenuItem
      value={branch.slug}
      key={branch.slug}
      id={branch.slug}
    >
      {branch.name}
    </MenuItem>
  ));

  let listItems = null;
  if (selectedBranch !== null && selectedBranch !== '') {
    if (categories && categories.length > 0) {
      listItems = categories.map((categoryItem) => (
        <ListItem
          key={categoryItem.slug}
          className="categoryRow"
          id={categoryItem.slug}
        >
          <ListItemText
            primary={categoryItem.name}
            secondary={<span style={{ fontSize: '12px' }}>{`Slug: ${categoryItem.slug}`}</span>}
          />
          <IconButton
            id={`edit${categoryItem.slug}IconButton`}
            className="editButton"
            onClick={() => { selectCategory(categoryItem.slug); }}
          >
            <Edit />
          </IconButton>
        </ListItem>
      ));
    } else {
      listItems = (
        <ListItem
          className="categoryRow"
        >
          <ListItemText
            primary="No existen categorías para este ramo, crea alguna usando el botón de añadir"
          />
        </ListItem>
      );
    }
  } else {
    listItems = (
      <ListItem
        className="categoryRow"
      >
        <ListItemText
          primary="Selecciona un Ramo"
        />
      </ListItem>
    );
  }


  return (
    <FrameContainer>
      <div>
        <div style={{
          display: 'flex',
          padding: '10px',
          margin: '0 auto',
          flexGrow: 1,
          flexDirection: 'column',
        }}
        >
          <div style={{
            flexDirection: 'row',
            width: '100%',
            display: 'flex',
          }}
          >
            <div
              style={{
                flexGrow: 1,
              }}
            >
              <FormControl
                id="branchSelector"
              >
                <InputLabel shrink htmlFor="select-branch-placeholder">
                  Ramo
                </InputLabel>
                <Select
                  autoWidth
                  input={<Input name="type" id="select-branch-placeholder" />}
                  style={{
                    minWidth: 150,
                  }}
                  key="branchSelector"
                  value={selectedBranch !== null ? selectedBranch : ''}
                  onChange={(event) => {
                    const selected = branches.find((row) => row.slug === event.target.value);
                    selectBranch(selected !== undefined ? selected : null);
                  }}
                >
                  {showbranches}
                </Select>
              </FormControl>
            </div>
            <Button
              variant="contained"
              id="addCategoryButton"
              key="addCategoryButton"
              style={{
                alignSelf: 'flex-end',
                margin: '5px',
              }}
              disabled={selectedBranch === null || selectBranch === ''}
              onClick={addButtonClick}
              color="primary"
            >
              Añadir
            </Button>
          </div>
          <Paper>
            <List
              id="categoryList"
            >
              {listItems}
            </List>
          </Paper>
        </div>
        {category !== null
          ? (
            <CategoryEditorComponent
              save={save}
              category={category}
              cancelEdit={cancelEdit}
            />
          )
          : null}
      </div>
    </FrameContainer>
  );
};


CategoriesListComponent.propTypes = {
  branches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedBranch: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectCategory: PropTypes.func.isRequired,
  selectBranch: PropTypes.func,
  addButtonClick: PropTypes.func.isRequired,
  category: PropTypes.shape({}),
  save: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

CategoriesListComponent.defaultProps = {
  selectBranch: null,
  selectedBranch: null,
  category: null,
};
export default CategoriesListComponent;
