import { actions, BACKENDS } from '../Constants';

export function clearCategories() {
  return {
    type: actions.CLEAR_CATEGORIES,
  };
}

export function categoriesFetchSuccess(branches) {
  return {
    type: actions.FETCH_CATEGORIES_SUCCESS,
    payload: branches,
  };
}

export function categoriesFetchError(error) {
  return {
    type: actions.FETCH_CATEGORIES_ERROR,
    error,
  };
}

export function fetchCategories(branchSlug) {
  return {
    type: actions.FETCH_CATEGORIES,
    apiConfig: {
      backend: BACKENDS.LIBRARY,
      url: `quotes/categories/${branchSlug}`,
      method: 'get',
      auth: true,
      success: categoriesFetchSuccess,
      error: categoriesFetchError,
      data: {},
      showLoad: true,
    },
  };
}

export function saveCategory(id, name, branchSlug, branchId) {
  return {
    type: actions.SAVE_CATEGORY,
    apiConfig: {
      backend: BACKENDS.LIBRARY,
      url: `quotes/categories/${id}`,
      method: 'put',
      auth: true,
      success: () => fetchCategories(branchSlug),
      error: categoriesFetchError,
      json: {
        name,
        branch_id: branchId,
      },
      showLoad: true,
    },
  };
}

export function createCategory(name, branchId, branchSlug) {
  return {
    type: actions.CREATE_CATEGORY,
    apiConfig: {
      backend: BACKENDS.LIBRARY,
      url: 'quotes/categories',
      method: 'post',
      auth: true,
      success: () => fetchCategories(branchSlug),
      error: categoriesFetchError,
      data: {
        name,
        branch_id: branchId,
      },
      showLoad: true,
    },
  };
}
