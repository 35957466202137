// ACTIONS
export const SHOW_LOAD = 'SHOW_LOAD';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const LOGIN_REFRESH_TOKEN_SUCCESS = 'LOGIN_REFRESH_TOKEN_SUCCESS';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const PERMISSION_SUCCESS = 'PERMISSION_SUCCESS';

export const FETCH_INSURERS = 'FETCH_INSURERS';
export const SAVE_INSURERS = 'SAVE_INSURERS';
export const PICK_INSURER = 'PICK_INSURER';
export const SAVE_INSURER_ERROR = 'SAVE_INSURER_ERROR';
export const FETCH_INSURERS_SUCCESS = 'FETCH_INSURERS_SUCCESS';
export const FETCH_INSURERS_ERROR = 'FETCH_INSURERS_ERROR';

export const FETCH_BRANCHES = 'FETCH_BRANCHES';
export const FETCH_BRANCHES_SUCCESS = 'FETCH_BRANCHES_SUCCESS';
export const FETCH_BRANCHES_ERROR = 'FETCH_BRANCHES_ERROR';
export const PICK_BRANCH = 'PICK_BRANCH';

export const FETCH_COVERAGES = 'FETCH_COVERAGES';
export const FETCH_COVERAGES_SUCCESS = 'FETCH_COVERAGES_SUCCESS';
export const FETCH_COVERAGES_ERROR = 'FETCH_COVERAGES_ERROR';
export const SAVE_COVERAGE = 'SAVE_COVERAGE';
export const SAVE_COVERAGE_SUCCESS = 'SAVE_COVERAGE_SUCCESS';
export const CREATE_COVERAGE = 'CREATE_COVERAGE';

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR';
export const SAVE_CATEGORY = 'SAVE_CATEGORY';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CLEAR_CATEGORIES = 'CLEAR_CATEGORIES';

export const FETCH_MODALITIES = 'FETCH_MODALITIES';
export const FETCH_MODALITIES_ERROR = 'FETCH_MODALITIES_ERROR';
export const FETCH_MODALITIES_SUCCESS = 'FETCH_MODALITIES_SUCCESS';
export const FETCH_MODALITY = 'FETCH_MODALITY';
export const FETCH_MODALITY_SUCCESS = 'FETCH_MODALITY_SUCCESS';
export const FETCH_MODALITY_ERROR = 'FETCH_MODALITY_ERROR';
export const CREATE_MODALITY = 'CREATE_MODALITY';
export const SAVE_MODALITY = 'SAVE_MODALITY';
export const SAVE_MODALITY_SUCCESS = 'SAVE_MODALITY_SUCCESS';
export const SAVE_MODALITY_ERROR = 'SAVE_MODALITY_ERROR';
export const DELETE_MODALITY_ERROR = 'DELETE_MODALITY_ERROR';
export const DELETE_MODALITY = 'DELETE_MODALITY';
export const CLEAR_SELECTED_MODALITY = 'CLEAR_SELECTED_MODALITY';
export const MERGE_MODALITY = 'MERGE_MODALITY';
export const MERGE_MODALITY_SUCCESS = 'MERGE_MODALITY_SUCCESS';
export const MERGE_MODALITY_ERROR = 'MERGE_MODALITY_ERROR';

export const PICK_MODALITIES_LIST_BRANCH = 'PICK_MODALITIES_LIST_BRANCH';
export const PICK_MODALITIES_LIST_INSURER = 'PICK_MODALITIES_LIST_INSURER';
export const SET_FILTER_PENDING_VALIDATION = 'SET_FILTER_PENDING_VALIDATION';

export const FETCH_VERSION = 'FETCH_VERSION';
export const FETCH_VERSION_SUCCESS = 'FETCH_VERSION_SUCCESS';
