import React from 'react';
import PropTypes from 'react-proptypes';
import {
  TableCell, Table, TableRow, TableBody, TableHead,
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { Add } from '@material-ui/icons';
import IntegrationEditorRow from './IntegrationEditorRow';

export default function IntegrationEditor({
  integrations,
  createIntegration,
  changeIntegration,
  deleteIntegration,
}) {
  const integrationsRow = integrations.map(
    (integration) => (
      <IntegrationEditorRow
        key={integration.internal_id}
        integration={integration}
        changeIntegration={changeIntegration}
        deleteIntegration={deleteIntegration}
      />
    ),
  );

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '20%' }}>ID Externa</TableCell>
            <TableCell style={{ width: '70%' }}>Integración</TableCell>
            <TableCell style={{ width: '10%' }}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {integrationsRow}
        </TableBody>
      </Table>

      <Fab
        id="createIntegrationButton"
        color="primary"
        aria-label="Añadir integración"
        style={{
          float: 'right',
          margin: '10px 0 0',
        }}
        onClick={createIntegration}
      >
        <Add />
      </Fab>
    </div>
  );
}

IntegrationEditor.propTypes = {
  integrations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  createIntegration: PropTypes.func.isRequired,
  changeIntegration: PropTypes.func.isRequired,
  deleteIntegration: PropTypes.func.isRequired,
};
