import uuidv4 from 'uuid';
import { actions } from '../Constants';

const initialState = {
  modalities: [],
  modalityToEdit: null,
  selectedBranch: null,
  selectedInsurer: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.CLEAR_SELECTED_MODALITY:
      return {
        ...state,
        modalityToEdit: null,
      };
    case actions.FETCH_MODALITY_SUCCESS:
      return {
        ...state,
        modalityToEdit: {
          ...action.payload,
          integrations: action.payload.integrations.map((integration) => ({
            ...integration,
            internal_id: uuidv4(),
          })),
        },
      };
    case actions.FETCH_MODALITIES_SUCCESS:
      return {
        ...state,
        modalities: action.payload,
      };
    case actions.PICK_MODALITIES_LIST_BRANCH:
      return {
        ...state,
        selectedBranch: action.branch,
      };
    case actions.PICK_MODALITIES_LIST_INSURER:
      return {
        ...state,
        selectedInsurer: action.insurer,
      };
    default:
      return state;
  }
};
