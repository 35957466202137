const loginRoot = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
};

const input = {
  margin: '10px',
  float: 'left',
};

const inputRow = {
  flexDirection: 'row',
  marginBottom: '10px',
};

const loginForm = {
  display: 'inline-flex',
  padding: '10px',
  height: 'auto',
  alignItems: 'center',
  flexDirection: 'column',
};

export default {
  loginForm, loginRoot, input, inputRow,
};
