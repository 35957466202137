import { actions } from '../Constants';

const initialState = {
  insurers: null,
  show_error: false,
  error_message: '',
  insurer: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_INSURERS:
      return {
        ...state,
      };
    case actions.FETCH_INSURERS_SUCCESS:
      return {
        ...state,
        insurers: action.payload,
      };
    case actions.FETCH_INSURERS_ERROR:
      return {
        ...state,
        show_error: true,
        error_message: action.error,
      };
    case actions.SAVE_INSURER_ERROR:
      return {
        ...state,
        show_error: true,
        error_message: action.error,
      };
    case actions.CLOSE_DIALOG:
      return {
        ...state,
        show_error: false,
        error_message: '',
      };
    case actions.PICK_INSURER: {
      return {
        ...state,
        insurer: action.insurer,
      };
    }
    default:
      return state;
  }
};
