import React, { Component } from 'react';
import PropTypes from 'react-proptypes';
import { connect } from 'react-redux';
import InsurerBranchPickerComponent from '../Components/InsurerBranchPickerComponent';

class InsurerBranchPickerContainer extends Component {
  selectBranch = (branch) => {
    const { onChange } = this.props;
    onChange('branch', branch);
  }

  selectInsurer = (insurer) => {
    const { onChange } = this.props;
    onChange('insurer', insurer);
  }

  render = () => {
    const {
      branches, insurers, branch, insurer,
    } = this.props;

    return (
      <InsurerBranchPickerComponent
        branches={branches}
        insurers={insurers}
        branch={branch}
        insurer={insurer}
        selectBranch={this.selectBranch}
        selectInsurer={this.selectInsurer}
        selectProduct={this.selectProduct}
      />
    );
  }
}

export const mapStateToProps = (state) => ({
  branches: state.Branches.branches,
  insurers: state.Insurers.insurers,
});

const ConnectedInsurerBranchPickerContainer = connect(
  mapStateToProps,
  null,
)(InsurerBranchPickerContainer);

InsurerBranchPickerContainer.propTypes = {
  branch: PropTypes.shape(),
  insurer: PropTypes.shape(),
  branches: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  insurers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
};

InsurerBranchPickerContainer.defaultProps = {
  branch: null,
  insurer: null,
};

export default ConnectedInsurerBranchPickerContainer;
