import { actions } from '../Constants';

const initialState = {
  version: null,
  shouldUpdate: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_VERSION_SUCCESS: {
      const shouldUpdate = state.version !== null
        && action.payload.version !== state.version;
      return {
        ...state,
        version: action.payload.version,
        shouldUpdate: !state.shouldUpdate ? shouldUpdate : state.shouldUpdate,
      };
    }
    default:
      return state;
  }
};
