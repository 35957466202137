import { combineReducers } from 'redux';
import Login from './Login';
import Insurers from './Insurers';
import Branches from './Branches';
import Coverages from './Coverages';
import Common from './Common';
import Categories from './Categories';
import Modalities from './Modalities';
import Version from './Version';


export default combineReducers({
  Login, Insurers, Branches, Coverages, Common, Categories, Modalities, Version,
});
