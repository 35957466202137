import { actions } from '../Constants';

export function showLoad(show, message = 'loading') {
  return {
    type: actions.SHOW_LOAD,
    show_load: show,
    message,
  };
}

export function closeDialog() {
  return {
    type: actions.CLOSE_DIALOG,
  };
}
