import React from 'react';
import PropTypes from 'react-proptypes';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Switch, Route, BrowserRouter as BaseRouter } from 'react-router-dom';
import LoginContainer from './Containers/LoginContainer';
import PrivateAppContainer from './Containers/PrivateAppContainer';
import RootComponent from './Containers/RootComponent';
import CommonContainer from './Containers/CommonContainer';
import settings from './settings'

const config = {
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: settings.mainColor,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: settings.mainColor,
    },
  },
};

const theme = createMuiTheme(config);

export default function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <BaseRouter>
        <Switch>
          <Route
            exact
            path="/login"
            render={() => (
              <LoginContainer />
            )}
          />
          <Route
            path="/"
            render={(props) => (
              <PrivateAppContainer>
                <RootComponent
                  history={props.history}
                />
              </PrivateAppContainer>
            )}
          />
        </Switch>
      </BaseRouter>
      <CommonContainer />
    </MuiThemeProvider>
  );
}

App.propTypes = {
  history: PropTypes.shape({}),
};

App.defaultProps = {
  history: null,
};
