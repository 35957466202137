import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'react-proptypes';
import {
  Modalities,
} from '../Redux/Actions';
import ModalityEditorContainer from './ModalityEditorContainer';

class CreateModalityContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modality: {
        id: null,
        name: '',
        description: '',
        slug: '',
        category: null,
        branch: props.selectedBranch,
        insurer: props.selectedInsurer,
        coverages: [],
        integrations: [],
        type: null,
        pending_validation: false,
      },
    };
  }

  saveEdit = () => {
    const { actions: { save }, history } = this.props;
    const { modality } = this.state;
    save(modality);
    history.push('/modalities');
  }

  cancelEdit = () => {
    const { history } = this.props;
    history.push('/modalities');
  }

  onChange = (modality) => {
    this.setState({ modality });
  }

  render = () => {
    const { modality } = this.state;

    return (
      <ModalityEditorContainer
        title="Crear una modalidad"
        cancelEdit={this.cancelEdit}
        modality={modality}
        onChange={this.onChange}
        save={this.saveEdit}
      />
    );
  }
}

export const mapStateToProps = (state) => ({
  selectedBranch: state.Modalities.selectedBranch,
  selectedInsurer: state.Modalities.selectedInsurer,
});

export const mapDispatchToProps = (dispatch) => ({
  actions: {
    save: (data) => {
      dispatch(Modalities.createModality(data));
    },
  },
});

const ConnectedCreateModalityContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateModalityContainer);

CreateModalityContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    save: PropTypes.func.isRequired,
  }).isRequired,
  selectedBranch: PropTypes.shape(),
  selectedInsurer: PropTypes.shape(),
};

CreateModalityContainer.defaultProps = {
  selectedBranch: null,
  selectedInsurer: null,
};

export default ConnectedCreateModalityContainer;
