import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'react-proptypes';
import { Branches, Insurers } from '../Redux/Actions';

class PrivateAppContainer extends Component {
  render() {
    const {
      logged, branches, insurers, children, actions: { loadBranches, loadInsurers },
    } = this.props;
    if (!logged) {
      return (
        <Redirect to={{
          pathname: '/login',
        }}
        />
      );
    }

    let loaded = true;
    if (insurers === null) {
      loaded = false;
      loadInsurers();
    }
    if (branches === null) {
      loaded = false;
      loadBranches();
    }


    return loaded ? children : null;
  }
}

export const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadBranches: () => {
      dispatch(Branches.fetchBranches());
    },
    loadInsurers: () => {
      dispatch(Insurers.fetchInsurers());
    },
  },
});

export const mapStateToProps = (state) => ({
  logged: state.Login.logged,
  branches: state.Branches.branches,
  insurers: state.Insurers.insurers,
});

const ConnectedPrivateAppContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateAppContainer);

PrivateAppContainer.propTypes = {
  logged: PropTypes.bool.isRequired,
  children: PropTypes.shape({}).isRequired,
  branches: PropTypes.arrayOf(PropTypes.shape()),
  insurers: PropTypes.arrayOf(PropTypes.shape()),
  actions: PropTypes.shape({
    loadBranches: PropTypes.func.isRequired,
    loadInsurers: PropTypes.func.isRequired,
  }).isRequired,
};

PrivateAppContainer.defaultProps = {
  branches: null,
  insurers: null,
};

export default ConnectedPrivateAppContainer;
