import React from 'react';
import PropTypes from 'react-proptypes';
import { Switch, Route, Redirect } from 'react-router-dom';
import InsurersContainer from './InsurersContainer';
import CoveragesContainer from './CoveragesContainer';
import CategoriesContainer from './CategoriesContainer';
import ModalitiesContainer from './ModalitiesContainer';
import CreateModalityContainer from './CreateModalityContainer';
import EditModalityContainer from './EditModalityContainer';

const RootComponent = () => (
  <Switch>
    <Route
      exact
      path="/"
      render={() => (<Redirect to="/modalities" />)}
    />
    <Route
      exact
      path="/insurers"
      render={() => (
        <InsurersContainer />
      )}
    />
    <Route
      exact
      path="/coverages"
      render={() => (
        <CoveragesContainer />
      )}
    />
    <Route
      exact
      path="/modalities"
      render={(props) => (
        <ModalitiesContainer
          history={props.history}
        />
      )}
    />
    <Route
      exact
      path="/modalities/new"
      render={(props) => (
        <CreateModalityContainer
          history={props.history}
        />
      )}
    />
    <Route
      exact
      path="/modalities/:modality"
      render={(props) => (
        <EditModalityContainer
          history={props.history}
        />
      )}
    />
    <Route
      exact
      path="/categories"
      render={() => (
        <CategoriesContainer />
      )}
    />
  </Switch>
);

RootComponent.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

export default RootComponent;
