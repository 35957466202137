import React from 'react';
import PropTypes from 'react-proptypes';
import {
  Dialog, CircularProgress, Button, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';

const CommonComponent = ({
  showError, onClose, errorMessage, showLoading, loadMessage,
}) => [
  <Dialog
    fullWidth
    key="dialogError"
    open={showError}
  >
    <DialogTitle>Error</DialogTitle>
    <DialogContent>
      <span id="errorDialogMessage" style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>{errorMessage}</span>
    </DialogContent>
    <DialogActions>
      <Button
        variant="text"
        id="errorDialogClose"
        key="errorDialogClose"
        color="primary"
        onClick={onClose}
      >
            Cerrar
      </Button>
    </DialogActions>
  </Dialog>,
  <Dialog
    key="loadError"
    fullWidth
    open={showLoading}
  >
    <DialogContent>
      <div style={{
        display: 'flex', flexDirection: 'row', flexItemAlign: 'center', padding: 15,
      }}
      >
        <CircularProgress />
        {' '}
        <span style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>{loadMessage}</span>
      </div>
    </DialogContent>
  </Dialog>,
];

CommonComponent.propTypes = {
  showLoading: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  loadMessage: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

CommonComponent.defaultProps = {
  onClose: null,
};

export default CommonComponent;
