import React, { Component } from 'react';
import PropTypes from 'react-proptypes';
import {
  Dialog, TextField, Button, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';

class CategoryEditorComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.computeInitialState(props.category ? props.category : null);
  }

    computeInitialState = (category) => {
      // No debería llegar nunca
      if (category === null) {
        return {
          id: '',
          name: '',
          slug: '',
        };
      }
      return {
        id: category.id,
        name: category.name !== null ? category.name : '',
        slug: category.slug,
      };
    }

    handleNameChange = (event) => {
      this.setState({
        name: event.currentTarget.value,
      });
    }

    render() {
      const { name } = this.state;
      const { cancelEdit, save } = this.props;

      const actions = [
        <Button
          variant="text"
          color="primary"
          id="cancelCategoryButton"
          key="cancelCategoryButton"
          onClick={() => {
            cancelEdit();
          }}
        >
          Cancelar
        </Button>,
        <Button
          variant="contained"
          color="primary"
          id="saveCategoryButton"
          key="saveCategoryButton"
          disabled={name === ''}
          onClick={() => {
            save(this.state);
          }}
        >
          Guardar
        </Button>,
      ];

      return (
        <Dialog
          open
          fullWidth
        >
          <DialogTitle>Editar categoría</DialogTitle>
          <DialogContent>
            <TextField
              value={name}
              id="nameCategoryInputField"
              key="nameCategoryInputField"
              onChange={this.handleNameChange}
              label="Nombre"
            />
          </DialogContent>
          <DialogActions>{actions}</DialogActions>
        </Dialog>
      );
    }
}

CategoryEditorComponent.propTypes = {
  save: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  category: PropTypes.shape({}).isRequired,
};

export default CategoryEditorComponent;
