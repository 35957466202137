import React, { Component } from 'react';
import PropTypes from 'react-proptypes';
import {
  Dialog, TextField, Button, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';

class CoverageEditorComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.computeInitialState(props.coverage ? props.coverage : null);
  }

    computeInitialState = (coverage) => {
      // No debería llegar nunca
      if (coverage === null) {
        return {
          id: '',
          name: null,
          slug: '',
          description: null,
        };
      }
      return {
        id: coverage.id,
        name: coverage.name,
        description: coverage.description,
        slug: coverage.slug,
      };
    }

    handleNameChange = (event) => {
      this.setState({
        name: event.currentTarget.value !== '' ? event.currentTarget.value : null,
      });
    }

    handleDescriptionChange = (event) => {
      this.setState({
        description: event.currentTarget.value !== '' ? event.currentTarget.value : null,
      });
    }

    render() {
      const { cancelEdit, save } = this.props;
      const { name, description } = this.state;

      return (
        <Dialog
          open
          fullWidth
        >
          <DialogTitle>Editar Cobertura</DialogTitle>
          <DialogContent>
            <TextField
              id="coverageNameInput"
              style={{ width: '100%' }}
              value={name !== null ? name : ''}
              onChange={this.handleNameChange}
              label="Nombre"
            />
            <TextField
              id="coverageDescriptionInput"
              value={description !== null ? description : ''}
              onChange={this.handleDescriptionChange}
              label="Descripción"
              multiline
              rowsMax={6}
              style={{
                width: '100%',
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                cancelEdit();
              }}
            >
              Cancelar
            </Button>
            <Button
              id="saveCoverageButton"
              variant="contained"
              color="primary"
              disabled={name === null}
              onClick={() => {
                save(this.state);
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
}

CoverageEditorComponent.propTypes = {
  save: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  coverage: PropTypes.shape({}).isRequired,
};

export default CoverageEditorComponent;
