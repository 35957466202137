import { actions } from '../Constants';
import { Login } from '../Actions';

const AfterReducerMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      store.dispatch(Login.getPermissions());
      break;
    default:
      break;
  }

  return result;
};

export default AfterReducerMiddleware;
