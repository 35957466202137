import React, { Component } from 'react';
import PropTypes from 'react-proptypes';
import { TextField, Button, Paper } from '@material-ui/core';
import styles from '../static/Login.css';
import settings from '../settings'

class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }

    handleUsernameChange = (event) => {
      this.setState({
        username: event.target.value,
        usernameError: '',
      });
    }

    handlePasswordChange = (event) => {
      this.setState({
        password: event.target.value,
        passwordError: '',
      });
    }

    validateInputs = () => {
      let valid = true;
      const { username, password } = this.state;
      if (username === '') {
        valid = false;
        this.setState({
          usernameError: 'El usuario es obligatorio',
        });
      }

      if (password === '') {
        valid = false;
        this.setState({
          passwordError: 'La constraseña es obligatoria',
        });
      }
      return valid;
    }

    render = () => {
      const {
        onSubmit,
      } = this.props;
      const {
        username, password, usernameError, passwordError,
      } = this.state;
      return (
        <div style={styles.loginRoot}>
          <div style={{ margin: '40px' }}>
            <img src={settings.assets.login_logo} alt="brand" />
          </div>
          <Paper style={styles.loginForm}>
            <div style={styles.inputRow}>
              <TextField
                style={styles.input}
                id="userInput"
                error={usernameError !== ''}
                helperText={usernameError}
                onChange={this.handleUsernameChange}
                label="Usuario"
              />
              <TextField
                type="password"
                style={styles.input}
                id="passwordInput"
                error={passwordError !== ''}
                helperText={passwordError}
                onChange={this.handlePasswordChange}
                label="Contraseña"
              />
            </div>
            <Button
              id="login-button"
              variant="contained"
              onClick={() => {
                if (this.validateInputs()) {
                  onSubmit(username, password);
                }
              }}
              color="primary"
            >
              Login
            </Button>
          </Paper>
        </div>
      );
    }
}

LoginComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginComponent;
