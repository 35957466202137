import { actions, BACKENDS } from '../Constants';

export function clearModalityToEdit() {
  return {
    type: actions.CLEAR_SELECTED_MODALITY,
  };
}

export function modalityFetchSuccess(modality) {
  return {
    type: actions.FETCH_MODALITY_SUCCESS,
    payload: modality,
  };
}

export function modalityFetchError(error) {
  return {
    type: actions.FETCH_MODALITY_ERROR,
    error,
  };
}

export function fetchModality(modalityId) {
  return {
    type: actions.FETCH_MODALITY,
    apiConfig: {
      backend: BACKENDS.LIBRARY,
      url: `quotes/modalities/${modalityId}`,
      method: 'get',
      auth: true,
      success: modalityFetchSuccess,
      error: modalityFetchError,
      data: {},
      showLoad: true,
    },
  };
}

export function modalitiesFetchSuccess(modalitys) {
  return {
    type: actions.FETCH_MODALITIES_SUCCESS,
    payload: modalitys,
  };
}

export function modalitiesFetchError(error) {
  return {
    type: actions.FETCH_MODALITIES_ERROR,
    error,
  };
}

export function fetchModalities(branchId, insurerId) {
  return {
    type: actions.FETCH_MODALITIES,
    apiConfig: {
      backend: BACKENDS.LIBRARY,
      url: `quotes/modalities/list/${branchId}/${insurerId}/false`, // el false es temporal
      method: 'get',
      auth: true,
      success: modalitiesFetchSuccess,
      error: modalitiesFetchError,
      data: {},
      showLoad: true,
    },
  };
}

export function serializeModalitydata(data) {
  const postdata = {};
  postdata.name = data.name;
  postdata.description = data.description;
  postdata.branch_id = data.branch.id;
  postdata.insurer_id = data.insurer.id;
  postdata.type = data.type;
  postdata.pending_validation = data.pending_validation;
  if (Object.keys(data).includes('category') && data.category !== null) {
    postdata.category_id = data.category.id;
  }
  data.coverages.forEach((coverage, index) => {
    postdata[`coverages_ids[${index}]`] = coverage.coverage.id;
    postdata[`coverages_descriptions[${coverage.coverage.id}]`] = coverage.description;
    postdata[`coverages_capitals[${coverage.coverage.id}]`] = coverage.capital;
    postdata[`coverages_franchises[${coverage.coverage.id}]`] = coverage.franchise;
    postdata[`coverages_percentages[${coverage.coverage.id}]`] = coverage.percentage;
  });

  data.integrations.forEach((integration, index) => {
    postdata[`integrations_modality_ids[${index}]`] = integration.modality_id;
    postdata[`integrations_external_ids[${index}]`] = integration.external_id;
    postdata[`integrations_integrations[${index}]`] = integration.integration;
  });

  return postdata;
}

export function modalitiesSaveError(error) {
  return {
    type: actions.SAVE_MODALITY_ERROR,
    error,
  };
}

export function createModality(data) {
  return {
    type: actions.CREATE_MODALITY,
    apiConfig: {
      backend: BACKENDS.LIBRARY,
      url: 'quotes/modalities',
      method: 'post',
      auth: true,
      success: () => fetchModalities(data.branch.id, data.insurer.id),
      error: modalitiesSaveError,
      data: serializeModalitydata(data),
      showLoad: true,
    },
  };
}

export function saveModality(data) {
  return {
    type: actions.SAVE_MODALITY,
    apiConfig: {
      backend: BACKENDS.LIBRARY,
      url: `quotes/modalities/${data.id}`,
      method: 'post',
      auth: true,
      success: () => fetchModalities(data.branch.id, data.insurer.id),
      error: modalitiesSaveError,
      data: serializeModalitydata(data),
      showLoad: true,
    },
  };
}

export function modalitiesDeleteError(error) {
  return {
    type: actions.DELETE_MODALITY_ERROR,
    error,
  };
}

export function deleteModality(modality) {
  return {
    type: actions.DELETE_MODALITY,
    apiConfig: {
      backend: BACKENDS.LIBRARY,
      url: `quotes/modalities/${modality.id}`,
      method: 'delete',
      auth: true,
      success: () => fetchModalities(modality.branch.id, modality.insurer.id),
      error: modalitiesDeleteError,
      showLoad: true,
    },
  };
}

export function modalitiesMergeError(error) {
  return {
    type: actions.MERGE_MODALITY_ERROR,
    error,
  };
}

export function modalitiesMergeSuccess() {
  return {
    type: actions.MERGE_MODALITY_SUCCESS,
  };
}

export function mergeModality(sourceId, destinationId, branchId, insurerId) {
  return {
    type: actions.MERGE_MODALITY,
    apiConfig: {
      backend: BACKENDS.LIBRARY,
      url: `quotes/modalities/merge/${sourceId}/${destinationId}`,
      method: 'post',
      auth: true,
      success: () => fetchModalities(branchId, insurerId), // fixme esto creo que es inutil
      error: modalitiesMergeError,
      showLoad: true,
    },
  };
}

export function pickListBranch(branch) {
  return {
    type: actions.PICK_MODALITIES_LIST_BRANCH,
    branch,
  };
}
export function pickListInsurer(insurer) {
  return {
    type: actions.PICK_MODALITIES_LIST_INSURER,
    insurer,
  };
}
