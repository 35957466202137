import { actions, BACKENDS } from '../Constants';

export function insurersFetchSuccess(insurers) {
  return {
    type: actions.FETCH_INSURERS_SUCCESS,
    payload: insurers,
  };
}

export function insurersFetchError(error) {
  return {
    type: actions.FETCH_INSURERS_ERROR,
    error,
  };
}

export function fetchInsurers() {
  return {
    type: actions.FETCH_INSURERS,
    apiConfig: {
      backend: BACKENDS.LIBRARY,
      url: 'quotes/insurers',
      method: 'get',
      auth: true,
      success: insurersFetchSuccess,
      error: insurersFetchError,
      data: {},
      showLoad: true,
    },
  };
}

export function insurerSaveError(error) {
  return {
    type: actions.SAVE_INSURER_ERROR,
    error,
  };
}

export function saveInsurer(name, slug, id, logo, dgsCode) {
  const data = {
    name,
    slug,
    dgs_code: dgsCode,
  };
  if (logo !== null) {
    data.logo = logo;
  }
  return {
    type: actions.SAVE_INSURERS,
    apiConfig: {
      backend: BACKENDS.LIBRARY,
      url: `quotes/insurers/${id}`,
      method: 'put',
      auth: true,
      success: fetchInsurers,
      error: insurerSaveError,
      json: data,
      showLoad: true,
    },
  };
}

export function pickInsurer(insurer) {
  return {
    type: actions.PICK_INSURER,
    insurer,
  };
}
