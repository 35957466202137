import { actions, BACKENDS } from '../Constants';

export function versionFetchSuccess(version) {
  return {
    type: actions.FETCH_VERSION_SUCCESS,
    payload: version,
  };
}

export function fetchVersion() {
  const date = new Date();
  return {
    type: actions.FETCH_VERSION,
    apiConfig: {
      backend: BACKENDS.LOCAL,
      url: `/version.json?t=${date.getTime()}`,
      method: 'get',
      auth: false,
      success: versionFetchSuccess,
      data: {},
      showLoad: false,
    },
  };
}
