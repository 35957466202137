import React, { PureComponent } from 'react';
import PropTypes from 'react-proptypes';
import { connect } from 'react-redux';
import { Common } from '../Redux/Actions';
import CommonComponent from '../Components/CommonComponent';

class UnConnectedCommonContainer extends PureComponent {
  render = () => {
    const {
      showLoading, loadMessage, showError,
      errorMessage, actions: { onClose },
    } = this.props;

    return (
      <CommonComponent
        showLoading={showLoading}
        loadMessage={loadMessage}
        showError={showError}
        errorMessage={errorMessage}
        onClose={onClose}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    onClose: () => {
      dispatch(Common.closeDialog());
    },
  },
});

const mapStateToProps = (state) => ({
  showLoading: state.Common.show_load,
  loadMessage: state.Common.load_message,
  showError: state.Common.show_error,
  errorMessage: state.Common.error_message,
});

const CommonContainer = connect(mapStateToProps, mapDispatchToProps)(UnConnectedCommonContainer);

UnConnectedCommonContainer.propTypes = {
  showLoading: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  loadMessage: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    onClose: PropTypes.func.isRequired,
  }).isRequired,
};

export default CommonContainer;
