import { actions } from '../Constants';

const initialState = {
  categories: [],
  show_error: false,
  error_message: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      };
    case actions.FETCH_CATEGORIES_ERROR:
      return {
        ...state,
        show_error: true,
        error_message: action.error,
      };
    case actions.CLEAR_CATEGORIES:
      return {
        ...state,
        categories: [],
      };
    default:
      return state;
  }
};
