import React, { Component } from 'react';
import PropTypes from 'react-proptypes';
import {
  Dialog, TextField, Avatar, Button, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';

const TWO_MEGS = 2;

class InsurerEditorComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.computeInitialState(props.insurer ? props.insurer : null);
  }

  computeInitialState = (insurer) => {
    // No debería llegar nunca
    if (insurer === null) {
      return {
        id: '',
        name: '',
        slug: '',
        logo: null,
        dgsCode: '',
      };
    }
    return {
      id: insurer.id,
      name: insurer.name !== null ? insurer.name : '',
      slug: insurer.slug,
      logo: null,
      dgsCode: insurer.dgsCode,
    };
  }

  handleNameChange = (event) => {
    this.setState({
      name: event.currentTarget.value,
    });
  }

  changeLogo = (event) => {
    if (event.currentTarget.files.length > 0) {
      const file = event.currentTarget.files[0];
      const size = ((file.size / 1024) / 1024).toFixed(4);
      if (size > TWO_MEGS) {
        // TODO ERROR
      } else {
        const fr = new FileReader();
        fr.onload = (event2) => {
          this.setState({
            logo: {
              preview: event2.target.result,
              file,
            },
          });
        };
        fr.readAsDataURL(file);
      }
    }
  }

  render() {
    const { insurer, cancelEdit, save } = this.props;
    const { name, logo: stateLogo } = this.state;

    let { logo } = insurer;
    if (stateLogo) {
      logo = stateLogo.preview;
    }

    return (
      <Dialog
        open
        fullWidth
      >
        <DialogTitle>
          Editar Aseguradora
        </DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <label
            htmlFor="logo_upload"
            style={{
              marginRight: 15,
            }}
          >
            <input
              id="logo_upload"
              accept="image/*"
              type="file"
              style={{
                display: 'none',
              }}
              onChange={this.changeLogo}
            />
            <Avatar
              size={200}
              src={logo}
            />
          </label>
          <TextField
            value={name}
            id="insurerName"
            onChange={this.handleNameChange}
            label="Nombre"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              cancelEdit();
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            id="saveInsurerButton"
            color="primary"
            disabled={name === ''}
            onClick={() => {
              save(this.state);
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

InsurerEditorComponent.propTypes = {
  insurer: PropTypes.shape({
    logo: PropTypes.string.isRequired,
  }).isRequired,
  cancelEdit: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

export default InsurerEditorComponent;
