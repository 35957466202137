import React, { Component } from 'react';
import PropTypes from 'react-proptypes';
import {
  List, ListItem, ListItemText, IconButton, Paper,
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import MergeType from '@material-ui/icons/MergeType';
import FrameContainer from './FrameContainer';
import InsurerBranchPickerContainer from '../Containers/InsurerBranchPickerContainer';

class ModalitiesListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalityToDelete: null,
      modalityToMergeSrc: null,
      modalityToMergeDst: null,
    };
  }

  selectMergeSrc = (modality) => {
    this.setState({
      modalityToMergeSrc: modality,
      modalityToMergeDst: null,
    });
  }

  selectMergeDst = (modality) => {
    this.setState({
      modalityToMergeDst: modality,
    });
  }

  showDeleteModality = (modality) => {
    this.setState({
      modalityToDelete: modality,
    });
  }

  clearModalityToMerge = () => {
    this.setState({
      modalityToMergeSrc: null,
      modalityToMergeDst: null,
    });
  }

  clearModalityToDelete = () => {
    this.setState({
      modalityToDelete: null,
    });
  }

  render() {
    const {
      selectModality, modalities, addButtonClick,
      deleteModality, pickerChange, selectedBranch, selectedInsurer,
    } = this.props;
    const { modalityToDelete, modalityToMergeSrc, modalityToMergeDst } = this.state;

    let listItems = null;
    let itemsToMerge = null;
    if (modalities && modalities.length > 0) {
      ({ listItems, itemsToMerge } = modalities.reduce((prev, modality) => {
        prev.listItems.push((
          <ListItem
            key={modality.id}
          >
            <ListItemText
              primary={modality.name}
              secondary={`Numero coberturas: ${modality.coverages.length}`}
            />
            <IconButton
              onClick={() => { this.selectMergeSrc(modality); }}
            >
              <MergeType />
            </IconButton>
            <IconButton
              onClick={() => { this.showDeleteModality(modality); }}
            >
              <Delete />
            </IconButton>
            <IconButton
              onClick={() => { selectModality(modality); }}
            >
              <Edit />
            </IconButton>
          </ListItem>
        ));

        if (modalityToMergeSrc !== null && modality.id !== modalityToMergeSrc.id) {
          prev.itemsToMerge.push((
            <ListItem
              button
              key={`${modality.id}_merge`}
              onClick={() => {
                this.selectMergeDst(modality);
              }}
            >
              <ListItemText
                primary={modality.name}
              />
            </ListItem>
          ));
        }

        return prev;
      }, { listItems: [], itemsToMerge: [] }));
    } else {
      listItems = (
        <ListItem>
          <ListItemText
            primary="No existen modalidades para esta combinación, crea alguno usando el botón de añadir"
          />
        </ListItem>
      );
    }

    return (
      <FrameContainer>
        <div>
          <div style={{
            display: 'flex', padding: '10px', margin: '0 auto', flexGrow: 1, flexDirection: 'column',
          }}
          >
            <div
              style={{
                flexDirection: 'row',
                width: '100%',
                display: 'flex',
                marginBottom: 10,
              }}
            >
              <InsurerBranchPickerContainer
                onChange={pickerChange}
                branch={selectedBranch}
                insurer={selectedInsurer}
              />
              <Button
                id="createButton"
                variant="contained"
                style={{
                  alignSelf: 'flex-end',
                  margin: '5px',
                }}
                onClick={addButtonClick}
                color="primary"
              >
                Añadir
              </Button>
            </div>
            <Paper>
              <List
                id="modalitiesList"
              >
                {listItems}
              </List>
            </Paper>
          </div>
          <Dialog
            open={modalityToDelete !== null}
          >
            <DialogTitle>Borrar</DialogTitle>
            <DialogContent>
              <span>¿Estás seguro de querer borrar esta modalidad?</span>
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                onClick={() => {
                  this.clearModalityToDelete();
                }}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  this.clearModalityToDelete();
                  deleteModality(modalityToDelete);
                }}
              >
                Borrar
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={modalityToMergeSrc !== null && modalityToMergeDst === null}
          >
            <DialogTitle>Seleccionar modalidad de destino</DialogTitle>
            <DialogContent>
              <List>
                {itemsToMerge}
              </List>
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  this.clearModalityToMerge();
                }}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={modalityToMergeSrc !== null && modalityToMergeDst !== null}
          >
            <DialogTitle>¿Estás seguro de querer combinar estas modalidades?</DialogTitle>
            <DialogActions>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  this.clearModalityToMerge();
                }}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  const {
                    modalityToMergeSrc: srcModality,
                    modalityToMergeDst: dstModality,
                  } = this.state;
                  const { merge } = this.props;
                  this.clearModalityToMerge();
                  merge(srcModality, dstModality);
                }}
              >
                Combinar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </FrameContainer>
    );
  }
}

ModalitiesListComponent.propTypes = {
  selectedBranch: PropTypes.shape(),
  selectedInsurer: PropTypes.shape(),
  pickerChange: PropTypes.func.isRequired,
  selectModality: PropTypes.func.isRequired,
  deleteModality: PropTypes.func.isRequired,
  merge: PropTypes.func.isRequired,
  modalities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addButtonClick: PropTypes.func.isRequired,
};

ModalitiesListComponent.defaultProps = {
  selectedBranch: null,
  selectedInsurer: null,
};

export default ModalitiesListComponent;
