import * as actions from './Actions';
import settings from '../../settings';

const LIBRARY = 'LIBRARY_BACKEND';
const API = 'API_BACKEND';
const LOCAL = 'LOCAL_BACKEND';

const api = {
  LibraryApiEndpoint: settings.LibraryApiEndpoint,
  ApiEndpoint: settings.ApiEndpoint,
  localEndpoint: settings.localEndpoint,

};
const BACKENDS = {
  LIBRARY,
  API,
  LOCAL,
};

export { actions, api, BACKENDS };
