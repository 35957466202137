import React, { Component } from 'react';
import PropTypes from 'react-proptypes';
import { connect } from 'react-redux';
import { Categories } from '../Redux/Actions';
import ModalityFormComponent from '../Components/ModalityFormComponent';

class ModalityEditorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: this.validateModality(props.modality),
    };

    const { actions: { loadCategories } } = props;

    if (props.modality.branch !== null) {
      loadCategories(props.modality.branch);
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const { modality, actions: { clearCategories, loadCategories } } = nextProps;
    const { modality: oldModality } = this.props;
    if (modality.product !== oldModality.product) {
      if (modality.branch !== null) {
        loadCategories(modality.branch);
      } else {
        clearCategories();
      }
    }
  }

  stripTags = (text) => text.replace(/(<([^>]+)>)/ig, '')

  validateModality = (modality) => {
    let valid = true;
    if (modality.name === '' || modality.branch === null
      || modality.insurer === null || this.type === null
      || this.stripTags(modality.description).trim() === '') {
      valid = false;
    }

    // Comprobamos si alguna de las integraciones no se puede guardar
    if (modality.integrations
      .some((integration) => integration.external_id === null
        || integration.integration === null)) {
      valid = false;
    }

    return valid;
  }

  handleFieldChange = (modalityField, value) => {
    const { modality: oldModality, onChange } = this.props;
    const modality = {
      ...oldModality,
      [modalityField]: value,
    };

    this.setState({
      valid: this.validateModality(modality),
    });

    onChange(modality);
  }

  render = () => {
    const {
      modality, save, cancelEdit, categories, title,
    } = this.props;
    const { valid } = this.state;


    return (
      <ModalityFormComponent
        title={title}
        cancelEdit={cancelEdit}
        modality={modality}
        categories={categories}
        enableSaveButton={valid}
        handleFieldChange={this.handleFieldChange}
        save={save}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadCategories: (branch) => {
      dispatch(Categories.fetchCategories(branch.slug));
    },
    clearCategories: () => {
      dispatch(Categories.clearCategories());
    },
  },
});

const mapStateToProps = (state) => ({
  categories: state.Categories.categories,
});

const ConnectedModalityEditorContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalityEditorContainer);

ModalityEditorContainer.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.shape({
    loadCategories: PropTypes.func.isRequired,
    clearCategories: PropTypes.func.isRequired,
  }).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  modality: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

ModalityEditorContainer.defaultProps = {
  title: null,
};

export default ConnectedModalityEditorContainer;
