import { actions } from '../Constants';

const initialState = {
  branches: null,
  show_error: false,
  error_message: '',
  branch: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_BRANCHES_SUCCESS:
      return {
        ...state,
        branches: action.payload,
      };
    case actions.FETCH_BRANCHES_ERROR:
      return {
        ...state,
        show_error: true,
        error_message: action.error,
      };
    case actions.CLOSE_DIALOG:
      return {
        ...state,
        show_error: false,
        error_message: '',
      };
    case actions.PICK_BRANCH: {
      return {
        ...state,
        branch: action.branch,
      };
    }
    default:
      return state;
  }
};
