import { actions, BACKENDS } from '../Constants';

export function loginSuccess(username, json) {
  return {
    type: actions.LOGIN_SUCCESS,
    payload: {
      username,
      token: json.token,
      refresh_token: json.refresh_token,
    },
  };
}

export function loginError(error) {
  return {
    type: actions.LOGIN_ERROR,
    error,
  };
}

export function login(username, password) {
  return {
    type: actions.LOGIN,
    apiConfig: {
      backend: BACKENDS.API,
      url: 'private/admin/login',
      method: 'post',
      success: (json) => loginSuccess(username, json),
      error: loginError,
      data: {
        username,
        password,
      },
      showLoad: true,
    },
  };
}

export function permissionSuccess(result) {
  return {
    type: actions.PERMISSION_SUCCESS,
    payload: result,
  };
}

export function getPermissions() {
  return {
    type: actions.GET_PERMISSIONS,
    apiConfig: {
      backend: BACKENDS.API,
      url: 'private/admin/permissions',
      method: 'get',
      success: permissionSuccess,
      error: loginError,
      auth: true,
      showLoad: true,
    },
  };
}

export function refreshTokenSuccess(token) {
  return {
    type: actions.LOGIN_REFRESH_TOKEN_SUCCESS,
    token,
  };
}

export function logout() {
  return {
    type: actions.LOGOUT,
  };
}
