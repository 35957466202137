import React, { PureComponent } from 'react';
import PropTypes from 'react-proptypes';
import { withStyles } from '@material-ui/core/styles';
import {
  Select, MenuItem, FormControl, InputLabel, Input,
} from '@material-ui/core';

const styles = () => ({
  selectors: {
    minWidth: 150,
    marginLeft: 10,
  },
});

class InsurerBranchPickerComponent extends PureComponent {
  render = () => {
    const {
      branches, insurers, insurer, branch,
      selectInsurer, selectBranch, classes,
    } = this.props;
    const branchOptions = branches.map((branchRow) => (
      <MenuItem
        value={branchRow.slug}
        key={branchRow.slug}
      >
        {branchRow.name}
      </MenuItem>
    ));

    const insurerOptions = insurers.map((insurerRow) => (
      <MenuItem
        value={insurerRow.slug}
        key={insurerRow.slug}
      >
        {insurerRow.name}
      </MenuItem>
    ));
    return (
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <FormControl>
          <InputLabel shrink htmlFor="select-insurer-placeholder">
            Aseguradora
          </InputLabel>
          <Select
            id="select-insurer"
            className={classes.selectors}
            autoWidth
            value={insurer !== null ? insurer.slug : ''}
            input={<Input name="insurer" id="select-insurer-placeholder" />}
            onChange={(event) => {
              const selected = insurers.find((row) => row.slug === event.target.value);
              selectInsurer(selected !== undefined ? selected : null);
            }}
          >
            {insurerOptions}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel shrink htmlFor="select-branch-placeholder">
            Ramo
          </InputLabel>
          <Select
            id="select-branch"
            autoWidth
            value={branch !== null ? branch.slug : ''}
            className={classes.selectors}
            input={<Input name="branch" id="select-branch-placeholder" />}
            onChange={(event) => {
              const selected = branches.find((row) => row.slug === event.target.value);
              selectBranch(selected !== undefined ? selected : null);
            }}
          >
            {branchOptions}
          </Select>
        </FormControl>
      </div>
    );
  }
}

InsurerBranchPickerComponent.propTypes = {
  classes: PropTypes.shape({
    selectors: PropTypes.string.isRequired,
  }).isRequired,
  branches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  insurers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  branch: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }),
  insurer: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }),
  selectBranch: PropTypes.func.isRequired,
  selectInsurer: PropTypes.func.isRequired,
};

InsurerBranchPickerComponent.defaultProps = {
  branch: null,
  insurer: null,
};

export default withStyles(styles)(InsurerBranchPickerComponent);
