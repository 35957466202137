import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import storeConfiguration from './Redux/Store/StoreConfiguration';
import statePersistence from './Redux/StatePersistence';

import App from './App';

import './index.css';
import { Version } from './Redux/Actions';

const store = storeConfiguration(statePersistence.recover());
store.dispatch(Version.fetchVersion());
setInterval(() => store.dispatch(Version.fetchVersion()), 300000);
let ErrorBoundary = Fragment;
if (process.env.APP_ENV === 'production' && process.env.BUGSNAG_API_KEY !== '') {
  const client = bugsnag(process.env.BUGSNAG_API_KEY);
  client.use(bugsnagReact, React);
  ErrorBoundary = client.getPlugin('react');
}

ReactDOM.render(
  (
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  ), document.getElementById('root'),
);
