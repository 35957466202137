import axios from 'axios';
import { Common, Login } from '../Actions';
import { api, BACKENDS } from '../Constants';

const EXPIRED_ACCESS_TOKEN_STATUS_CODE = 401;
const EXPIRED_REFRESH_TOKEN_STATUS_CODE = 418;

let request = null;
let subscribers = [];
let isRefreshing = false;

const subscribeTokenRefresh = (cb) => {
  subscribers.push(cb);
};

const onRefreshed = (token) => {
  subscribers.map((cb) => cb(token));
};

const logout = (store) => {
  isRefreshing = false;
  subscribers = [];
  store.dispatch(Common.showLoad(false));
  store.dispatch(Login.logout());
};

const getRequest = (store) => {
  if (request === null) {
    request = axios.create();

    request.interceptors.response.use(undefined, async (err) => {
      const {
        config,
        response: { status },
      } = err;
      const originalRequest = config;

      if (status === EXPIRED_REFRESH_TOKEN_STATUS_CODE) {
        logout(store);
      } else if (status === EXPIRED_ACCESS_TOKEN_STATUS_CODE) {
        if (config.url.indexOf('authorization/refresh') !== -1) {
          logout(store);
        } else if (!isRefreshing) {
          isRefreshing = true;
          const refreshToken = store.getState().Login.refresh_token;
          const accessToken = store.getState().Login.token;
          request
            .post(
              `${api.ApiEndpoint}private/authorization/refresh`,
              { refresh_token: refreshToken },
              { headers: { Authorization: `Bearer ${accessToken}` } },
            )
            .then((response) => {
              const { data } = response;
              isRefreshing = false;
              store.dispatch(Login.refreshTokenSuccess(data.token));
              onRefreshed(data.token);
              subscribers = [];
            });
        }

        const requestSubscribers = new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axios(originalRequest));
          });
        });

        return requestSubscribers;
      }

      return Promise.reject(err);
    });
  }
  return request;
};

const ApiMiddleware = (store) => (next) => (action) => {
  if (!action.apiConfig) {
    return next(action);
  }


  const config = action.apiConfig;

  if (config.showLoad) {
    store.dispatch(Common.showLoad(true));
  }

  let baseUrl = null;
  switch (config.backend) {
    case BACKENDS.LIBRARY:
      baseUrl = api.LibraryApiEndpoint;
      break;
    case BACKENDS.LOCAL:
      baseUrl = '';
      break;
    case BACKENDS.API:
    default:
      baseUrl = api.ApiEndpoint;
      break;
  }
  const params = {
    url: baseUrl + config.url,
    method: config.method,
  };

  let headers = {};
  if (config.json) {
    headers = {
      ...headers,
      'Content-Type': 'application/json',
    };
    params.data = JSON.stringify(config.json);
  } else if (config.data && Object.keys(config.data).length > 0) {
    params.data = new FormData();
    Object.keys(config.data).forEach((key) => {
      if (config.data[key] instanceof Array) {
        config.data[key].forEach((value) => {
          params.data.set(`${key}[]`, value);
        });
      } else {
        params.data.set(key, config.data[key]);
      }
    });
  }

  if (config.auth) {
    headers = {
      ...headers,
      Authorization: `Bearer ${store.getState().Login.token}`,
    };
  }

  params.headers = headers;

  getRequest(store).request(params)
    .then((response) => {
      switch (response.status) {
        case 204:
          return {
            json: null,
            ok: true,
          };
        case 201:
        case 200:
          return {
            json: response.data,
            ok: true,
          };

        default:
          return {
            error: 'Error con la red',
            ok: false,
          };
      }
    })
    .then((response) => {
      store.dispatch(Common.showLoad(false));
      if (response.ok) {
        if (config.success) {
          store.dispatch(config.success(response.json));
        }
      } else if (config.error) {
        if (Object.prototype.hasOwnProperty.call(response, 'error')) { // response.hasOwnProperty('error')
          store.dispatch(config.error(response.error));
        } else {
          store.dispatch(config.error(response.json));
        }
      }
    })
    .catch((error) => {
      store.dispatch(Common.showLoad(false));
      if (config.error) {
        store.dispatch(config.error(`error con la red: ${error.message}`));
      }
    });

  return next(action);
};

export default ApiMiddleware;
