const DATA_EXPIRATION = 7 * 24 * 60 * 60 * 1000;

const expiredDate = (date) => {
  const dat = new Date(date);
  const now = new Date();
  return (now - dat) > DATA_EXPIRATION;
};

const recover = () => {
  let persistedState;
  try {
    const savedData = JSON.parse(localStorage.getItem('login'));
    if (savedData) {
      // we only recover if we were logged, to avoid recover
      // an accidentally saved unlogged state
      if (!expiredDate(savedData.date)) {
        persistedState = {
          Login: { ...savedData.state },
        };
      } else {
        localStorage.removeItem('login');
      }
    }
  } catch (err) {
    console.log(err);// eslint-disable-line no-console
  }
  return persistedState;
};

const persist = (state) => {
  const now = new Date();
  try {
    localStorage.setItem('login', JSON.stringify({
      state: state.Login,
      date: now,
    }));
  } catch (err) {
    console.log(err);// eslint-disable-line no-console
  }
};

export default { recover, persist };
