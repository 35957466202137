import React from 'react';
import PropTypes from 'react-proptypes';
import {
  TableCell, TextField, TableRow, IconButton,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

export default function IntegrationEditorRow({
  integration,
  changeIntegration,
  deleteIntegration,
}) {
  return (
    <TableRow
      key={integration.internal_id}
    >
      <TableCell style={{ width: '20%' }}>
        <TextField
          id={`${integration.internal_id}_external_id`}
          variant="outlined"
          label="ID externa"
          placeholder="ID externa de la integración"
          value={integration.external_id !== null ? integration.external_id : ''}
          error={integration.external_id === null}
          onChange={(event) => {
            changeIntegration('external_id', event.currentTarget.value, integration.internal_id);
          }}
        />
      </TableCell>
      <TableCell style={{ width: '70%' }}>
        <TextField
          id={`${integration.internal_id}_integration`}
          fullWidth
          variant="outlined"
          label="Integración"
          placeholder="Identificador de la integración"
          value={integration.integration !== null ? integration.integration : ''}
          error={integration.integration === null}
          onChange={(event) => {
            changeIntegration('integration', event.currentTarget.value, integration.internal_id);
          }}
        />
      </TableCell>
      <TableCell style={{ width: '10%' }}>
        <IconButton
          id={`delete${integration.internal_id}IconButton`}
          className="deleteButton"
          onClick={() => { deleteIntegration(integration.internal_id); }}
        >
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

IntegrationEditorRow.propTypes = {
  integration: PropTypes.shape().isRequired,
  changeIntegration: PropTypes.func.isRequired,
  deleteIntegration: PropTypes.func.isRequired,
};
