import { actions } from '../Constants';

const initialState = {
  show_load: false,
  load_message: '',
  show_error: false,
  error_message: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SHOW_LOAD:
      return {
        ...state,
        show_load: action.show_load,
        load_message: action.message,
      };
    case actions.CLOSE_DIALOG:
      return {
        ...state,
        show_error: false,
        show_message: '',
      };
    case actions.LOGIN_ERROR:
    case actions.SAVE_INSURER_ERROR:
    case actions.FETCH_INSURER_ERROR:
    case actions.FETCH_INSURERS_ERROR:
    case actions.FETCH_BRANCHES_ERROR:
    case actions.FETCH_COVERAGES_ERROR:
    case actions.FETCH_PRODUCTS_ERROR:
    case actions.FETCH_PRODUCT_ERROR:
    case actions.SAVE_PRODUCT_ERROR:
    case actions.FETCH_CATEGORIES_ERROR:
    case actions.FETCH_MODALITIES_ERROR:
    case actions.FETCH_MODALITY_ERROR:
    case actions.SAVE_MODALITY_ERROR:
    case actions.DELETE_MODALITY_ERROR:
      return {
        ...state,
        show_error: true,
        error_message: action.error,
      };


    default:
      return state;
  }
};
