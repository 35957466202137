import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'react-proptypes';
import {
  Modalities,
} from '../Redux/Actions';
import ModalitiesListComponent from '../Components/ModalitiesListComponent';

class ModalitiesContainer extends Component {
  editModality = (modality) => {
    const { history } = this.props;
    history.push(`/modalities/${modality.id}`);
  }

  createModality = () => {
    const { history } = this.props;
    history.push('/modalities/new');
  }

  deleteModality = (modality) => {
    const { actions: { deleteModality } } = this.props;
    deleteModality(modality);
  }

  fetchModalities = (field, value) => {
    const {
      selectedBranch,
      selectedInsurer,
      actions: {
        fetchModalities, pickBranch, pickInsurer,
      },
    } = this.props;
    let branch = null;
    let insurer = null;
    switch (field) {
      case 'branch':
        pickBranch(value);
        branch = value;
        insurer = selectedInsurer;
        break;
      case 'insurer':
        pickInsurer(value);
        insurer = value;
        branch = selectedBranch;
        break;
      default:
        break;
    }

    if (insurer !== null && branch !== null) {
      fetchModalities(branch, insurer);
    }
  };

  mergeModality = (source, destination) => {
    const { selectedInsurer, selectedBranch, actions: { mergeModality } } = this.props;
    mergeModality(source, destination, selectedBranch, selectedInsurer);
  }

  render = () => {
    const {
      selectedInsurer, selectedBranch, modalities,
    } = this.props;

    return (
      <ModalitiesListComponent
        modalities={modalities}
        addButtonClick={this.createModality}
        selectModality={this.editModality}
        deleteModality={this.deleteModality}
        pickerChange={this.fetchModalities}
        merge={this.mergeModality}
        selectedBranch={selectedBranch}
        selectedInsurer={selectedInsurer}
      />
    );
  }
}

export const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchModalities: (branch, insurer) => {
      dispatch(Modalities.fetchModalities(branch.id, insurer.id));
    },
    deleteModality: (modality) => {
      dispatch(Modalities.deleteModality(modality));
    },
    pickBranch: (branch) => {
      dispatch(Modalities.pickListBranch(branch));
    },
    pickInsurer: (insurer) => {
      dispatch(Modalities.pickListInsurer(insurer));
    },
    mergeModality: (source, destination, branch, insurer) => {
      dispatch(Modalities.mergeModality(source.id, destination.id, branch.id, insurer.id));
    },
  },
});

export const mapStateToProps = (state) => ({
  modalities: state.Modalities.modalities,
  selectedBranch: state.Modalities.selectedBranch,
  selectedInsurer: state.Modalities.selectedInsurer,
});

const ConnectedModalitiesContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalitiesContainer));

ModalitiesContainer.propTypes = {
  modalities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedBranch: PropTypes.shape(),
  selectedInsurer: PropTypes.shape(),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    fetchModalities: PropTypes.func.isRequired,
    mergeModality: PropTypes.func.isRequired,
    deleteModality: PropTypes.func.isRequired,
    pickBranch: PropTypes.func.isRequired,
    pickInsurer: PropTypes.func.isRequired,
  }).isRequired,
};

ModalitiesContainer.defaultProps = {
  selectedBranch: null,
  selectedInsurer: null,
};

export default ConnectedModalitiesContainer;
