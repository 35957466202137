import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'react-proptypes';
import {
  Modalities,
} from '../Redux/Actions';
import ModalityEditorContainer from './ModalityEditorContainer';

class EditModalityContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modality: null,
    };
    props.actions.loadModality(props.match.params.modality);
  }

  componentWillReceiveProps = (nextProps) => {
    const {
      modality: modalityToEdit,
    } = nextProps;
    const { modality } = this.state;

    if (modalityToEdit !== null && modality === null) {
      this.setState({
        modality: modalityToEdit,
      });
    }
  }

  saveEdit = () => {
    const { history, actions: { save, clearModalityToEdit } } = this.props;
    const { modality } = this.state;
    save(modality);
    clearModalityToEdit();
    history.push('/modalities');
  }

  cancelEdit = () => {
    const { history, actions: { clearModalityToEdit } } = this.props;
    clearModalityToEdit();
    history.push('/modalities');
  }

  onChange = (modality) => {
    this.setState({
      modality,
    });
  }

  render = () => {
    const { modality } = this.state;
    if (modality === null) {
      return null;
    }

    return (
      <ModalityEditorContainer
        title="Editar una modalidad"
        cancelEdit={this.cancelEdit}
        modality={modality}
        onChange={this.onChange}
        save={this.saveEdit}
      />
    );
  }
}

export const mapStateToProps = (state) => ({
  modality: state.Modalities.modalityToEdit,
});

export const mapDispatchToProps = (dispatch) => ({
  actions: {
    save: (data) => {
      dispatch(Modalities.saveModality(data));
    },
    loadModality: (productId) => {
      dispatch(Modalities.fetchModality(productId));
    },
    clearModalityToEdit: () => {
      dispatch(Modalities.clearModalityToEdit());
    },
  },
});

const ConnectedEditModalityContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditModalityContainer));

EditModalityContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      modality: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    save: PropTypes.func.isRequired,
    loadModality: PropTypes.func.isRequired,
    clearModalityToEdit: PropTypes.func.isRequired,
  }).isRequired,
};

export default ConnectedEditModalityContainer;
