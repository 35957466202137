import { actions } from '../Constants';

const initialState = {
  coverages: [],
  show_error: false,
  error_message: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_COVERAGES_SUCCESS:
      return {
        ...state,
        coverages: action.payload,
      };
    case actions.FETCH_COVERAGES_ERROR:
      return {
        ...state,
        show_error: true,
        error_message: action.error,
      };
    case actions.CLOSE_DIALOG:
      return {
        ...state,
        show_error: false,
        error_message: '',
      };
    case actions.SAVE_COVERAGE_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
};
