import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'react-proptypes';

import {
  Branches, Coverages, Common,
} from '../Redux/Actions';
import CoveragesListComponent from '../Components/CoveragesListComponent';

class CoveragesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coverageToEdit: null,
    };
  }

  componentDidMount = () => {
    const { branch, actions: { fetchCoverages } } = this.props;
    if (branch !== null) {
      fetchCoverages(branch.slug);
    }
  }

  editCoverage = (coverageSlug) => {
    const { coverages } = this.props;
    let coverageToEdit = null;
    coverages.forEach((coverage) => {
      if (coverageToEdit !== null) return;
      if (coverage.slug === coverageSlug) {
        coverageToEdit = coverage;
      }
    });
    this.setState({
      coverageToEdit,
    });
  }

  createCoverage = () => {
    const { branch: { slug: branchSlug } } = this.props;
    this.setState({
      coverageToEdit: {
        id: null,
        name: null,
        slug: '',
        description: null,
        branch_id: branchSlug,
      },
    });
  }

  saveEdit = (data) => {
    const { actions: { create, save }, branch: { slug: branchSlug } } = this.props;
    if (data.id === null) {
      create(data.name, data.description, branchSlug);
    } else {
      save(
        data.id,
        data.name,
        data.description,
        data.slug,
        branchSlug,
      );
    }

    this.setState({
      coverageToEdit: null,
    });
  }

  cancelEdit = () => {
    this.setState({
      coverageToEdit: null,
    });
  }

  loadCoverages = (branch) => {
    const { actions: { fetchCoverages, pickBranch } } = this.props;
    fetchCoverages(branch.slug);
    pickBranch(branch);
  }

  render = () => {
    const {
      branches, coverages, branch,
    } = this.props;
    const { coverageToEdit } = this.state;

    return (
      <CoveragesListComponent
        branches={branches}
        selectedBranch={branch ? branch.slug : null}
        coverages={coverages}
        selectCoverage={this.editCoverage}
        selectBranch={this.loadCoverages}
        addButtonClick={this.createCoverage}
        coverage={coverageToEdit}
        save={this.saveEdit}
        cancelEdit={this.cancelEdit}
      />
    );
  }
}


export const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchCoverages: (branchSlug) => {
      dispatch(Coverages.fetchCoverages(branchSlug));
    },
    closeDialog: () => {
      dispatch(Common.closeDialog());
    },
    save: (id, name, description, slug, branchSlug) => {
      dispatch(Coverages.saveCoverage(id, name, description, slug, branchSlug));
    },
    create: (name, description, branchSlug) => {
      dispatch(Coverages.createCoverage(name, description, branchSlug));
    },
    pickBranch: (branch) => {
      dispatch(Branches.pickBranch(branch));
    },
  },
});

export const mapStateToProps = (state) => ({
  showLoading: state.Common.show_load,
  loadMessage: state.Common.load_message,
  showError: state.Branches.show_error || state.Coverages.show_error,
  errorMessage: state.Branches.error_message, // TODO FALTA EL ERROR DE COVERAGES
  branches: state.Branches.branches,
  coverages: state.Coverages.coverages,
  branch: state.Branches.branch,
});

const ConnectedCoveragesContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoveragesContainer));

CoveragesContainer.propTypes = {
  actions: PropTypes.shape({
    closeDialog: PropTypes.func.isRequired,
    fetchCoverages: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    pickBranch: PropTypes.func.isRequired,
  }).isRequired,
  branches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  coverages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  branch: PropTypes.shape({
    slug: PropTypes.string,
  }),
};

CoveragesContainer.defaultProps = {
  branch: null,
};

export default ConnectedCoveragesContainer;
