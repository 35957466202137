import { actions } from '../Constants';

const initialState = {
  logged: false,
  show_error: false,
  error_message: '',
  username: null,
  token: null,
  refresh_token: null,
  permissions: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        logged: true,
        username: action.payload.username,
        token: action.payload.token,
        refresh_token: action.payload.refresh_token,
      };
    case actions.PERMISSION_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
        logged: true,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        show_error: true,
        error_message: action.error,
      };
    case actions.CLOSE_DIALOG:
      return {
        ...state,
        show_error: false,
        error_message: '',
      };
    case actions.LOGIN_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token,
      };
    case actions.LOGOUT:
      return {
        ...state,
        logged: false,
        username: null,
        token: null,
        refresh_token: null,
      };
    default:
      return state;
  }
};
