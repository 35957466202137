import React, { Component } from 'react';
import PropTypes from 'react-proptypes';
import { connect } from 'react-redux';
import { Coverages } from '../Redux/Actions';
import CoverageEditor from '../Components/CoverageEditor';

class CoverageEditorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coveragesById: props.objectCoverages.reduce((prev, coverage) => ({
        ...prev,
        [coverage.coverage_id]: coverage,
      }), {}),
    };
  }

  componentDidMount = () => {
    const { branch } = this.props;
    if (branch !== null) {
      this.loadCoverages(branch);
    }
  }

  checkProductCoverage = (coverageId, checked) => {
    const { onChange, coverages } = this.props;
    const { coveragesById } = this.state;
    let newCoveragesById = {
      ...coveragesById,
    };
    if (checked) {
      if (!Object.keys(coveragesById).includes(coverageId)) {
        const coverageToAdd = coverages.find((coverage) => coverage.id === coverageId);
        if (coverageToAdd) {
          newCoveragesById = {
            ...coveragesById,
            [coverageId]: {
              coverage_id: coverageToAdd.id,
              coverage: coverageToAdd,
              franchise: 0,
              capital: 0,
              percentage: 0,
              description: '',
            },
          };
        }
      }
    } else if (Object.keys(coveragesById).includes(coverageId)) {
      newCoveragesById = Object.keys(coveragesById).reduce((prev, currentCoverageId) => {
        if (currentCoverageId !== coverageId) {
          return {
            ...prev,
            [currentCoverageId]: coveragesById[currentCoverageId],
          };
        }
        return prev;
      }, {});
    }

    this.setState({
      coveragesById: newCoveragesById,
    });
    onChange(Object.keys(newCoveragesById).map((index) => newCoveragesById[index]));
  }

  handleCoverageChange = (field, value, coverageId) => {
    const { onChange } = this.props;
    const { coveragesById } = this.state;
    const valueToSave = ['capital', 'percentage', 'franchise'].includes(field) && value === '' ? 0 : value;

    const newCoverages = {
      ...coveragesById,
      [coverageId]: {
        ...coveragesById[coverageId],
        [field]: valueToSave,
      },
    };

    this.setState({
      coveragesById: newCoverages,
    });
    onChange(Object.keys(newCoverages).map((indexedId) => (newCoverages[indexedId])));
  }

  loadCoverages = (branch) => {
    const { actions: { loadCoverages } } = this.props;
    loadCoverages(branch.slug);
  }

  render = () => {
    const { coveragesById } = this.state;
    const { coverages } = this.props;

    return (
      <CoverageEditor
        branchCoverages={coverages}
        modalityCoverages={coveragesById}
        handleCoverageChange={this.handleCoverageChange}
        checkProductCoverage={this.checkProductCoverage}
      />
    );
  }
}

export const mapStateToProps = (state) => ({
  coverages: state.Coverages.coverages,
});

export const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadCoverages: (branch) => {
      dispatch(Coverages.fetchCoverages(branch));
    },
  },
});

const ConnectedCoverageEditorContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoverageEditorContainer);

CoverageEditorContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  branch: PropTypes.shape(),
  objectCoverages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  coverages: PropTypes.arrayOf(PropTypes.shape()),
  actions: PropTypes.shape({
    loadCoverages: PropTypes.func.isRequired,
  }).isRequired,
};

CoverageEditorContainer.defaultProps = {
  branch: null,
  coverages: [],
};

export default ConnectedCoverageEditorContainer;
