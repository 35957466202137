import { actions, BACKENDS } from '../Constants';

export function branchesFetchSuccess(branches) {
  return {
    type: actions.FETCH_BRANCHES_SUCCESS,
    payload: branches,
  };
}

export function branchesFetchError(error) {
  return {
    type: actions.FETCH_BRANCHES_ERROR,
    error,
  };
}

export function fetchBranches() {
  return {
    type: actions.FETCH_BRANCHES,
    apiConfig: {
      backend: BACKENDS.LIBRARY,
      url: 'quotes/branches',
      method: 'get',
      auth: true,
      success: branchesFetchSuccess,
      error: branchesFetchError,
      data: {},
      showLoad: true,
    },
  };
}

export function pickBranch(branch) {
  return {
    type: actions.PICK_BRANCH,
    branch,
  };
}
