import React from 'react';
import PropTypes from 'react-proptypes';
import {
  List, ListItem, IconButton, Paper, ListItemText,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import InsurerEditorComponent from './InsurerEditorComponent';
import FrameContainer from './FrameContainer';


const InsurerListComponent = ({
  insurers, selectInsurer, saveEdit, insurerToEdit, cancelEdit,
}) => {
  const listItems = insurers.map((insurer) => {
    const selectInsurers = () => { selectInsurer(insurer.slug); };
    return (
      <ListItem
        key={insurer.slug}
      >
        {
          insurer.logo !== ''
          && <img alt={insurer.name} src={insurer.logo} />
        }
        <ListItemText
          primary={insurer.name}
        />
        <IconButton
          className="editButton"
          onClick={selectInsurers}
        >
          <Edit />
        </IconButton>
      </ListItem>
    );
  });
  return (
    <FrameContainer>
      <div>
        <div style={{
          display: 'flex', padding: '10px', margin: '0 auto', maxWidth: '850px', flexGrow: 1, flexDirection: 'column',
        }}
        >
          <Paper>
            <List
              id="insurersList"
            >
              {listItems}
            </List>
          </Paper>
        </div>
        { insurerToEdit !== null
          ? (
            <InsurerEditorComponent
              save={saveEdit}
              insurer={insurerToEdit}
              cancelEdit={cancelEdit}
            />
          )
          : null }
      </div>
    </FrameContainer>
  );
};

InsurerListComponent.propTypes = {
  insurers: PropTypes.arrayOf(PropTypes.shape).isRequired,
  selectInsurer: PropTypes.func.isRequired,
  saveEdit: PropTypes.func.isRequired,
  insurerToEdit: PropTypes.shape({}),
  cancelEdit: PropTypes.func.isRequired,
};

InsurerListComponent.defaultProps = {
  insurerToEdit: null,
};

export default InsurerListComponent;
