import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'react-proptypes';
import { Insurers, Common } from '../Redux/Actions';
import InsurerListComponent from '../Components/InsurerListComponent';

class InsurersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insurerToEdit: null,
    };
  }

  editInsurer = (insurerSlug) => {
    const { insurers } = this.props;
    let insurerToEdit = null;
    insurers.forEach((insurer) => {
      if (insurerToEdit !== null) return;
      if (insurer.slug === insurerSlug) {
        insurerToEdit = insurer;
      }
    });
    this.setState({
      insurerToEdit,
    });
  }

  saveEdit = (data) => {
    const { actions: { saveInsurer } } = this.props;
    let logo = null;
    if (data.logo !== null) {
      logo = data.logo.file;
    }
    saveInsurer(data.name, data.slug, data.id, logo, data.dgsCode);
    this.setState({
      insurerToEdit: null,
    });
  }

  cancelEdit = () => {
    this.setState({
      insurerToEdit: null,
    });
  }

  render = () => {
    const { insurers } = this.props;
    const { insurerToEdit } = this.state;

    return (
      <InsurerListComponent
        selectInsurer={this.editInsurer}
        insurers={insurers}
        saveEdit={this.saveEdit}
        insurerToEdit={insurerToEdit}
        cancelEdit={this.cancelEdit}
      />
    );
  }
}

export const mapDispatchToProps = (dispatch) => ({
  actions: {
    saveInsurer: (name, slug, id, logo, dgsCode) => {
      dispatch(Insurers.saveInsurer(name, slug, id, logo, dgsCode));
    },
    closeDialog: () => {
      dispatch(Common.closeDialog());
    },
  },
});

export const mapStateToProps = (state) => ({
  showLoading: state.Common.show_load,
  loadMessage: state.Common.load_message,
  showError: state.Insurers.show_error,
  errorMessage: state.Insurers.error_message,
  insurers: state.Insurers.insurers,
  shouldReloadInsurers: state.Insurers.should_reload_insurers,
});

const ConnectedInsurersContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(InsurersContainer));

InsurersContainer.propTypes = {
  insurers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  actions: PropTypes.shape({
    closeDialog: PropTypes.func.isRequired,
    saveInsurer: PropTypes.func.isRequired,
  }).isRequired,
};

export default ConnectedInsurersContainer;
