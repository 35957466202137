import { createStore, applyMiddleware } from 'redux';
import throttle from 'lodash/throttle';
import Reducers from '../Reducers';
import { ApiMiddleware, AfterReducerMiddleware } from '../Middlewares';
import statePersistence from '../StatePersistence';

export default function configureStore(persistedState) {
  const middlewares = [
    ApiMiddleware,
    AfterReducerMiddleware,
  ];

  const store = applyMiddleware(...middlewares)(createStore)(
    Reducers,
    persistedState || undefined,
  );

  store.subscribe(throttle(() => {
    statePersistence.persist(store.getState());
  }, 1000));

  return store;
}
