import React from 'react';
import PropTypes from 'react-proptypes';
import {
  TableCell, Table, TableRow, TableBody, TableHead,
} from '@material-ui/core';
import CoverageEditorRow from './CoverageEditorRow';

export default function CoverageEditor({
  branchCoverages,
  modalityCoverages,
  checkProductCoverage,
  handleCoverageChange,
}) {
  if (Object.keys(branchCoverages).length === 0) {
    return (
      <div style={{
        width: '1500px', height: '700px', textAlign: 'center', display: 'table',
      }}
      >
        <h1
          style={{
            margin: 'auto',
            display: 'table-cell',
            verticalAlign: 'middle',
          }}
        >
          Para poder crear un producto, primero necesitas crear alguna cobertura
        </h1>
      </div>
    );
  }

  const coveragesRow = (branchCoverages).map(
    (coverage) => {
      const modalityCoverage = Object.keys(modalityCoverages).includes(coverage.id)
        ? modalityCoverages[coverage.id]
        : null;
      return (
        <CoverageEditorRow
          key={coverage.id}
          coverage={coverage}
          modalityCoverage={modalityCoverage}
          handleCoverageChange={handleCoverageChange}
          checkProductCoverage={checkProductCoverage}
        />
      );
    },
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '150px' }}>Nombre</TableCell>
          <TableCell style={{ width: '30px' }}>Añadir</TableCell>
          <TableCell style={{ width: '50px' }}>Capital</TableCell>
          <TableCell style={{ width: '50px' }}>Franquicia</TableCell>
          <TableCell style={{ width: '50px' }}>Porcentaje</TableCell>
          <TableCell style={{ width: '550px' }}>Descripción</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {coveragesRow}
      </TableBody>
    </Table>
  );
}

CoverageEditor.propTypes = {
  branchCoverages: PropTypes.arrayOf(PropTypes.shape()),
  modalityCoverages: PropTypes.shape({}).isRequired,
  checkProductCoverage: PropTypes.func.isRequired,
  handleCoverageChange: PropTypes.func.isRequired,
};

CoverageEditor.defaultProps = {
  branchCoverages: [],
};
