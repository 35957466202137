import { actions, BACKENDS } from '../Constants';

export function coveragesFetchSuccess(coverages) {
  return {
    type: actions.FETCH_COVERAGES_SUCCESS,
    payload: coverages,
  };
}

export function coveragesFetchError(error) {
  return {
    type: actions.FETCH_COVERAGES_ERROR,
    error,
  };
}

export function fetchCoverages(branchSlug) {
  return {
    type: actions.FETCH_COVERAGES,
    apiConfig: {
      backend: BACKENDS.LIBRARY,
      url: `quotes/coverages/${branchSlug}`,
      method: 'get',
      auth: true,
      success: coveragesFetchSuccess,
      error: coveragesFetchError,
      data: {},
      showLoad: true,
    },
  };
}

export function saveCoverage(id, name, description, slug, branch) {
  const data = {
    slug,
  };

  if (name !== null) {
    data.name = name;
  }

  if (description !== null) {
    data.description = description;
  }
  return {
    type: actions.SAVE_COVERAGE,
    apiConfig: {
      backend: BACKENDS.LIBRARY,
      url: `quotes/coverages/${id}`,
      method: 'put',
      auth: true,
      success: () => fetchCoverages(branch),
      error: coveragesFetchError,
      json: data,
      showLoad: true,
    },
  };
}

export function createCoverage(name, description, branchSlug) {
  const data = {
    branch: branchSlug,
  };

  if (name !== null) {
    data.name = name;
  }

  if (description !== null) {
    data.description = description;
  }

  return {
    type: actions.CREATE_COVERAGE,
    apiConfig: {
      backend: BACKENDS.LIBRARY,
      url: 'quotes/coverages',
      method: 'post',
      auth: true,
      success: () => fetchCoverages(branchSlug),
      error: coveragesFetchError,
      data,
      showLoad: true,
    },
  };
}
