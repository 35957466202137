import React, { Component } from 'react';
import PropTypes from 'react-proptypes';
import uuidv4 from 'uuid';
import IntegrationEditor from '../Components/IntegrationEditor';

class IntegrationEditorContainer extends Component {
  createIntegration = () => {
    const { modalityId, objectIntegrations, onChange } = this.props;

    onChange([
      ...objectIntegrations,
      {
        internal_id: uuidv4(),
        modality_id: modalityId,
        external_id: null,
        integration: null,
      },
    ]);
  }

  changeIntegration = (field, value, internalId) => {
    const { objectIntegrations, onChange } = this.props;
    const valueToSave = ['external_id', 'integration'].includes(field) && value === '' ? null : value;

    const newIntegrationsToEdit = objectIntegrations.map((integrationObject) => {
      if (integrationObject.internal_id === internalId) {
        return {
          ...integrationObject,
          [field]: valueToSave,
        };
      }

      return integrationObject;
    });

    onChange(newIntegrationsToEdit);
  }

  deleteIntegration = (internalId) => {
    const { objectIntegrations, onChange } = this.props;
    onChange(objectIntegrations.filter((row) => row.internal_id !== internalId));
  }

  render = () => {
    const { objectIntegrations } = this.props;
    return (
      <IntegrationEditor
        integrations={objectIntegrations}
        createIntegration={this.createIntegration}
        changeIntegration={this.changeIntegration}
        deleteIntegration={this.deleteIntegration}
      />
    );
  }
}

IntegrationEditorContainer.propTypes = {
  modalityId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  objectIntegrations: PropTypes.arrayOf(PropTypes.shape()),
};

IntegrationEditorContainer.defaultProps = {
  modalityId: null,
  objectIntegrations: [],
};

export default IntegrationEditorContainer;
